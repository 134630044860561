import React from "react"
import Announcement from "./Announcement"
import Background from "./Background"
import Hero from "./Hero"

import * as introStyles from "../styles/components/intro.module.scss"

export default function Intro() {
  return (
    <div className="section">
      <section className={introStyles.intro}>
        <Announcement />
        <Background />
        <Hero />
      </section>
    </div>
  )
}
