import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import Noise from "./Noise"
import Socials from "./Socials"
import "@fontsource/marvel"
import "@fontsource/montserrat"

import * as footerStyles from "../styles/components/footer.module.scss"
import SvgLogo from "../../static/heather-logo.inline.svg"

import Sponsers from "../../static/sponsors.png"
import useSiteMetaData from "../static_queries/useSiteMetadata"

export default function Footer(props) {
  const { footerData } = useSiteMetaData()

  return (
    <div className={footerStyles.footer}>
      <section className={footerStyles.footer__first}>
        <Noise opacity={0.085} />
        <Container className={footerStyles.footer__container}>
          <Row>
            <Col className={footerStyles.band} sm={12} md={4}>
              <SvgLogo />
              <div
                dangerouslySetInnerHTML={{ __html: footerData.footer_text }}
              />
              <span>
                © Heather {(new Date().getFullYear())} • <Link to="press">Electronic Press Kit (EPK)</Link>
              </span>
            </Col>
            <Col sm={12} md={12}>
              <img
                className={footerStyles.sponsors}
                src={Sponsers}
                alt="Sponsors"
              />
            </Col>
            <Col className={footerStyles.socials} sm={12} md={8}>
              <Socials />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
