import React from "react"
import * as announcementStyles from "../styles/components/announcement.module.scss"
import useSiteMetaData from "../static_queries/useSiteMetadata"

export default function Announcement(props) {
  const { frontPageData } = useSiteMetaData()
  return (
    <>
      <div className={announcementStyles.announcementMobile__container}>
        <div className={announcementStyles.announcementMobileP}>
          <div
            dangerouslySetInnerHTML={{
              __html: frontPageData.mobile_announcement,
            }}
          />
        </div>
      </div>
      <div className={announcementStyles.announcement__container}>
        <div className={announcementStyles.announcement}>
          <div
            dangerouslySetInnerHTML={{
              __html: frontPageData.desktop_announcement,
            }}
          />
        </div>
      </div>
    </>
  )
}
