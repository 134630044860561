import React from "react"
import Layout from "../components/Layout"
import Background from "../components/Background"
import Event from "../components/Event"
import Intro from "../components/Intro"
import Footer from "../components/Footer"
import Gallery from "../components/Gallery"
import ReactFullpage from "@fullpage/react-fullpage"

import * as galleryStyles from "../styles/components/gallery.module.scss"

const FullPage = () => (
  <ReactFullpage
    licenseKey={"4E30C4A2-1925415D-81F23891-C8822545"}
    scrollOverflow={true}
    scrollBar={true}
    navigation
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Intro />
          <div className="section">
            <Gallery />
            <Footer />
          </div>
        </ReactFullpage.Wrapper>
      )
    }}
  />
)

export default function IndexPage() {
  var hideBandPicture = true

  return (
    <Layout page="home" bgColor="inherit">
      <Background hideBandPicture={hideBandPicture} />
      <FullPage />
    </Layout>
  )
}
