import React from "react"
import useGalleryData from "../static_queries/useGalleryData"
import Img from "gatsby-image"

import * as galleryStyles from "../styles/components/gallery.module.scss"

export default function Gallery() {
  const galleryData = useGalleryData()[0]

  return (
    <div className={galleryStyles.masonry_with_columns}>
      {galleryData.node.frontmatter.images.map(brick => {
        return (
          <div key={brick.childImageSharp.fluid}>
            <Img
              className={galleryStyles.masonry_image}
              fluid={brick.childImageSharp.fluid}
            />
          </div>
        )
      })}
    </div>
  )
}
