// extracted by mini-css-extract-plugin
export var accordionBody = "announcement-module--accordion-body--Of2vI";
export var accordionButton = "announcement-module--accordion-button--tiiMs";
export var accordionCollapse = "announcement-module--accordion-collapse--6z6Nc";
export var accordionFlush = "announcement-module--accordion-flush--M6ohh";
export var accordionHeader = "announcement-module--accordion-header--PB-J7";
export var accordionItem = "announcement-module--accordion-item--LDtGN";
export var active = "announcement-module--active--UEwq1";
export var alert = "announcement-module--alert--EvBYB";
export var alertDanger = "announcement-module--alert-danger--yHrhj";
export var alertDark = "announcement-module--alert-dark--nXsVN";
export var alertDismissible = "announcement-module--alert-dismissible--L3A9a";
export var alertHeading = "announcement-module--alert-heading--wsXpZ";
export var alertInfo = "announcement-module--alert-info--DYiPn";
export var alertLight = "announcement-module--alert-light--o1l4J";
export var alertLink = "announcement-module--alert-link--pCIXd";
export var alertPrimary = "announcement-module--alert-primary--jjoVb";
export var alertSecondary = "announcement-module--alert-secondary--cksiW";
export var alertSuccess = "announcement-module--alert-success--DtdHB";
export var alertWarning = "announcement-module--alert-warning--XuazL";
export var alignBaseline = "announcement-module--align-baseline--uJE6O";
export var alignBottom = "announcement-module--align-bottom--HIc0X";
export var alignContentAround = "announcement-module--align-content-around--JuSaM";
export var alignContentBetween = "announcement-module--align-content-between--qo+MR";
export var alignContentCenter = "announcement-module--align-content-center--ZPKFH";
export var alignContentEnd = "announcement-module--align-content-end--76Edq";
export var alignContentLgAround = "announcement-module--align-content-lg-around--csxmb";
export var alignContentLgBetween = "announcement-module--align-content-lg-between--bk8Fv";
export var alignContentLgCenter = "announcement-module--align-content-lg-center--Li6mz";
export var alignContentLgEnd = "announcement-module--align-content-lg-end--QNUtc";
export var alignContentLgStart = "announcement-module--align-content-lg-start--3RyK7";
export var alignContentLgStretch = "announcement-module--align-content-lg-stretch--BXBo0";
export var alignContentMdAround = "announcement-module--align-content-md-around--60Vxt";
export var alignContentMdBetween = "announcement-module--align-content-md-between--Y0z-b";
export var alignContentMdCenter = "announcement-module--align-content-md-center--0JXDE";
export var alignContentMdEnd = "announcement-module--align-content-md-end--N6jpL";
export var alignContentMdStart = "announcement-module--align-content-md-start--wvPft";
export var alignContentMdStretch = "announcement-module--align-content-md-stretch--FJKa-";
export var alignContentSmAround = "announcement-module--align-content-sm-around--tO2C7";
export var alignContentSmBetween = "announcement-module--align-content-sm-between--Qhc9K";
export var alignContentSmCenter = "announcement-module--align-content-sm-center--kvKKs";
export var alignContentSmEnd = "announcement-module--align-content-sm-end--HR-O+";
export var alignContentSmStart = "announcement-module--align-content-sm-start--rhJy8";
export var alignContentSmStretch = "announcement-module--align-content-sm-stretch--nReQy";
export var alignContentStart = "announcement-module--align-content-start--DonKF";
export var alignContentStretch = "announcement-module--align-content-stretch---i5cp";
export var alignContentXlAround = "announcement-module--align-content-xl-around--KswxR";
export var alignContentXlBetween = "announcement-module--align-content-xl-between--Ezxoh";
export var alignContentXlCenter = "announcement-module--align-content-xl-center--vtdw9";
export var alignContentXlEnd = "announcement-module--align-content-xl-end--UPUho";
export var alignContentXlStart = "announcement-module--align-content-xl-start--qY-0H";
export var alignContentXlStretch = "announcement-module--align-content-xl-stretch--JwQUM";
export var alignContentXxlAround = "announcement-module--align-content-xxl-around--TjuFp";
export var alignContentXxlBetween = "announcement-module--align-content-xxl-between--UIofP";
export var alignContentXxlCenter = "announcement-module--align-content-xxl-center--TEVCe";
export var alignContentXxlEnd = "announcement-module--align-content-xxl-end--16Dyy";
export var alignContentXxlStart = "announcement-module--align-content-xxl-start--SlUld";
export var alignContentXxlStretch = "announcement-module--align-content-xxl-stretch--isVoY";
export var alignItemsBaseline = "announcement-module--align-items-baseline--luP72";
export var alignItemsCenter = "announcement-module--align-items-center--AFZsx";
export var alignItemsEnd = "announcement-module--align-items-end--8pna9";
export var alignItemsLgBaseline = "announcement-module--align-items-lg-baseline--YhQZS";
export var alignItemsLgCenter = "announcement-module--align-items-lg-center--oiRi7";
export var alignItemsLgEnd = "announcement-module--align-items-lg-end--lzUUs";
export var alignItemsLgStart = "announcement-module--align-items-lg-start--WEbFz";
export var alignItemsLgStretch = "announcement-module--align-items-lg-stretch--AJ8YB";
export var alignItemsMdBaseline = "announcement-module--align-items-md-baseline--xGsGd";
export var alignItemsMdCenter = "announcement-module--align-items-md-center--vuEQr";
export var alignItemsMdEnd = "announcement-module--align-items-md-end--KrhIK";
export var alignItemsMdStart = "announcement-module--align-items-md-start--e3drP";
export var alignItemsMdStretch = "announcement-module--align-items-md-stretch--LPU+4";
export var alignItemsSmBaseline = "announcement-module--align-items-sm-baseline--vwy7G";
export var alignItemsSmCenter = "announcement-module--align-items-sm-center--P4k+B";
export var alignItemsSmEnd = "announcement-module--align-items-sm-end--ZMZXN";
export var alignItemsSmStart = "announcement-module--align-items-sm-start--zFi4r";
export var alignItemsSmStretch = "announcement-module--align-items-sm-stretch--8aEQE";
export var alignItemsStart = "announcement-module--align-items-start--fTDg6";
export var alignItemsStretch = "announcement-module--align-items-stretch--AvnKt";
export var alignItemsXlBaseline = "announcement-module--align-items-xl-baseline--53hmX";
export var alignItemsXlCenter = "announcement-module--align-items-xl-center--A6yRM";
export var alignItemsXlEnd = "announcement-module--align-items-xl-end--GSSei";
export var alignItemsXlStart = "announcement-module--align-items-xl-start--2pwBr";
export var alignItemsXlStretch = "announcement-module--align-items-xl-stretch--ZE5KX";
export var alignItemsXxlBaseline = "announcement-module--align-items-xxl-baseline--LvFdJ";
export var alignItemsXxlCenter = "announcement-module--align-items-xxl-center--l949G";
export var alignItemsXxlEnd = "announcement-module--align-items-xxl-end--wBanL";
export var alignItemsXxlStart = "announcement-module--align-items-xxl-start--oG3iH";
export var alignItemsXxlStretch = "announcement-module--align-items-xxl-stretch--9vX5j";
export var alignMiddle = "announcement-module--align-middle--z07Qe";
export var alignSelfAuto = "announcement-module--align-self-auto--DZvyJ";
export var alignSelfBaseline = "announcement-module--align-self-baseline--tKspI";
export var alignSelfCenter = "announcement-module--align-self-center--emHNz";
export var alignSelfEnd = "announcement-module--align-self-end--pj0y+";
export var alignSelfLgAuto = "announcement-module--align-self-lg-auto--HDLWn";
export var alignSelfLgBaseline = "announcement-module--align-self-lg-baseline--PcSGK";
export var alignSelfLgCenter = "announcement-module--align-self-lg-center--ehCms";
export var alignSelfLgEnd = "announcement-module--align-self-lg-end--jFQFb";
export var alignSelfLgStart = "announcement-module--align-self-lg-start--UM4BT";
export var alignSelfLgStretch = "announcement-module--align-self-lg-stretch--EjC7+";
export var alignSelfMdAuto = "announcement-module--align-self-md-auto--NEtg1";
export var alignSelfMdBaseline = "announcement-module--align-self-md-baseline--Lxtz2";
export var alignSelfMdCenter = "announcement-module--align-self-md-center--7KIZu";
export var alignSelfMdEnd = "announcement-module--align-self-md-end--hKiEt";
export var alignSelfMdStart = "announcement-module--align-self-md-start--gMHJx";
export var alignSelfMdStretch = "announcement-module--align-self-md-stretch--Ck0KU";
export var alignSelfSmAuto = "announcement-module--align-self-sm-auto--zP2kM";
export var alignSelfSmBaseline = "announcement-module--align-self-sm-baseline--uVwAS";
export var alignSelfSmCenter = "announcement-module--align-self-sm-center--Dls3r";
export var alignSelfSmEnd = "announcement-module--align-self-sm-end--XtyVw";
export var alignSelfSmStart = "announcement-module--align-self-sm-start--pI9Xh";
export var alignSelfSmStretch = "announcement-module--align-self-sm-stretch--8ljJT";
export var alignSelfStart = "announcement-module--align-self-start--UWyOp";
export var alignSelfStretch = "announcement-module--align-self-stretch--sDCpl";
export var alignSelfXlAuto = "announcement-module--align-self-xl-auto--bnYLT";
export var alignSelfXlBaseline = "announcement-module--align-self-xl-baseline--0fvg8";
export var alignSelfXlCenter = "announcement-module--align-self-xl-center--6f-xZ";
export var alignSelfXlEnd = "announcement-module--align-self-xl-end--drvwm";
export var alignSelfXlStart = "announcement-module--align-self-xl-start--guPP7";
export var alignSelfXlStretch = "announcement-module--align-self-xl-stretch--9-d2K";
export var alignSelfXxlAuto = "announcement-module--align-self-xxl-auto--DxeKp";
export var alignSelfXxlBaseline = "announcement-module--align-self-xxl-baseline--pGNnI";
export var alignSelfXxlCenter = "announcement-module--align-self-xxl-center--KvXXt";
export var alignSelfXxlEnd = "announcement-module--align-self-xxl-end--HhI3b";
export var alignSelfXxlStart = "announcement-module--align-self-xxl-start--pBZh0";
export var alignSelfXxlStretch = "announcement-module--align-self-xxl-stretch--2NKBC";
export var alignTextBottom = "announcement-module--align-text-bottom--nVmVE";
export var alignTextTop = "announcement-module--align-text-top--FusQV";
export var alignTop = "announcement-module--align-top--M2Cwc";
export var announcement = "announcement-module--announcement--HIE4S";
export var announcementMobileP = "announcement-module--announcementMobileP--mPXP8";
export var announcementMobile__container = "announcement-module--announcementMobile__container--wwGrj";
export var announcement__container = "announcement-module--announcement__container--8SSa9";
export var badge = "announcement-module--badge--n28I7";
export var bgBlack = "announcement-module--bg-black--edIoU";
export var bgBody = "announcement-module--bg-body--clzj4";
export var bgDanger = "announcement-module--bg-danger--dWbCm";
export var bgDark = "announcement-module--bg-dark--hMdjz";
export var bgGradient = "announcement-module--bg-gradient--xrY31";
export var bgInfo = "announcement-module--bg-info--B8joC";
export var bgLight = "announcement-module--bg-light--FNeIM";
export var bgOpacity10 = "announcement-module--bg-opacity-10--W5yNR";
export var bgOpacity100 = "announcement-module--bg-opacity-100--apv82";
export var bgOpacity25 = "announcement-module--bg-opacity-25--Nq4i2";
export var bgOpacity50 = "announcement-module--bg-opacity-50--7c2m8";
export var bgOpacity75 = "announcement-module--bg-opacity-75--Ih9JI";
export var bgPrimary = "announcement-module--bg-primary--DXw1a";
export var bgSecondary = "announcement-module--bg-secondary--K0pqm";
export var bgSuccess = "announcement-module--bg-success--KAoEc";
export var bgTransparent = "announcement-module--bg-transparent--nIBeQ";
export var bgWarning = "announcement-module--bg-warning--vOlNf";
export var bgWhite = "announcement-module--bg-white--i7KJs";
export var blockquote = "announcement-module--blockquote--1ct2r";
export var blockquoteFooter = "announcement-module--blockquote-footer--r4-5K";
export var border = "announcement-module--border--GMxHI";
export var border0 = "announcement-module--border-0--0vs3d";
export var border1 = "announcement-module--border-1--frqy6";
export var border2 = "announcement-module--border-2--80-zd";
export var border3 = "announcement-module--border-3--ywWg2";
export var border4 = "announcement-module--border-4--xwx1J";
export var border5 = "announcement-module--border-5--V5yk1";
export var borderBottom = "announcement-module--border-bottom--is-tK";
export var borderBottom0 = "announcement-module--border-bottom-0--f3OqW";
export var borderDanger = "announcement-module--border-danger--k2z1g";
export var borderDark = "announcement-module--border-dark--We9oz";
export var borderEnd = "announcement-module--border-end--E8MWD";
export var borderEnd0 = "announcement-module--border-end-0--XRiFk";
export var borderInfo = "announcement-module--border-info--O3UDg";
export var borderLight = "announcement-module--border-light--qPPbY";
export var borderPrimary = "announcement-module--border-primary--NooKo";
export var borderSecondary = "announcement-module--border-secondary--xLIIt";
export var borderStart = "announcement-module--border-start--FBbEW";
export var borderStart0 = "announcement-module--border-start-0--TVHhz";
export var borderSuccess = "announcement-module--border-success--QM2Lm";
export var borderTop = "announcement-module--border-top--mX5+A";
export var borderTop0 = "announcement-module--border-top-0--NCrrr";
export var borderWarning = "announcement-module--border-warning--KFUFI";
export var borderWhite = "announcement-module--border-white--V-aB2";
export var bottom0 = "announcement-module--bottom-0--fA3jZ";
export var bottom100 = "announcement-module--bottom-100--uDJO4";
export var bottom50 = "announcement-module--bottom-50--y68bc";
export var breadcrumb = "announcement-module--breadcrumb--w4pQk";
export var breadcrumbItem = "announcement-module--breadcrumb-item--sgFrI";
export var bsPopoverAuto = "announcement-module--bs-popover-auto--e9fbt";
export var bsPopoverBottom = "announcement-module--bs-popover-bottom--gdQV1";
export var bsPopoverEnd = "announcement-module--bs-popover-end--1tRWg";
export var bsPopoverStart = "announcement-module--bs-popover-start--Ava7n";
export var bsPopoverTop = "announcement-module--bs-popover-top--ObH7K";
export var bsTooltipAuto = "announcement-module--bs-tooltip-auto--+4rSN";
export var bsTooltipBottom = "announcement-module--bs-tooltip-bottom--paQ2b";
export var bsTooltipEnd = "announcement-module--bs-tooltip-end--FKkbJ";
export var bsTooltipStart = "announcement-module--bs-tooltip-start--VDIkb";
export var bsTooltipTop = "announcement-module--bs-tooltip-top--XRbjB";
export var btn = "announcement-module--btn--ecf1O";
export var btnCheck = "announcement-module--btn-check--zyGD8";
export var btnClose = "announcement-module--btn-close--DDP+y";
export var btnCloseWhite = "announcement-module--btn-close-white--ohBsK";
export var btnDanger = "announcement-module--btn-danger--6jXF7";
export var btnDark = "announcement-module--btn-dark--vC-7J";
export var btnGroup = "announcement-module--btn-group--5qMRd";
export var btnGroupLg = "announcement-module--btn-group-lg--kE3iw";
export var btnGroupSm = "announcement-module--btn-group-sm--sjHbC";
export var btnGroupVertical = "announcement-module--btn-group-vertical--4djGr";
export var btnInfo = "announcement-module--btn-info--9Aco9";
export var btnLg = "announcement-module--btn-lg--LDP1I";
export var btnLight = "announcement-module--btn-light--l8QRL";
export var btnLink = "announcement-module--btn-link--HHUn7";
export var btnOutlineDanger = "announcement-module--btn-outline-danger--9FMmZ";
export var btnOutlineDark = "announcement-module--btn-outline-dark--G9tRW";
export var btnOutlineInfo = "announcement-module--btn-outline-info--DcFi5";
export var btnOutlineLight = "announcement-module--btn-outline-light--flzHC";
export var btnOutlinePrimary = "announcement-module--btn-outline-primary--dafXG";
export var btnOutlineSecondary = "announcement-module--btn-outline-secondary--NFB86";
export var btnOutlineSuccess = "announcement-module--btn-outline-success--VZHw5";
export var btnOutlineWarning = "announcement-module--btn-outline-warning--e8uDN";
export var btnPrimary = "announcement-module--btn-primary--vXJ-S";
export var btnSecondary = "announcement-module--btn-secondary--ddY4A";
export var btnSm = "announcement-module--btn-sm--n0urg";
export var btnSuccess = "announcement-module--btn-success--mO2cz";
export var btnToolbar = "announcement-module--btn-toolbar--SQLoo";
export var btnWarning = "announcement-module--btn-warning--M8p5z";
export var captionTop = "announcement-module--caption-top--toNZh";
export var card = "announcement-module--card--nvJ3t";
export var cardBody = "announcement-module--card-body--vDYCq";
export var cardFooter = "announcement-module--card-footer--KTo2h";
export var cardGroup = "announcement-module--card-group--ZEcNy";
export var cardHeader = "announcement-module--card-header--UFQUn";
export var cardHeaderPills = "announcement-module--card-header-pills--tlIXC";
export var cardHeaderTabs = "announcement-module--card-header-tabs--XKGmc";
export var cardImg = "announcement-module--card-img--fq23Z";
export var cardImgBottom = "announcement-module--card-img-bottom--2iVc4";
export var cardImgOverlay = "announcement-module--card-img-overlay--WCPhj";
export var cardImgTop = "announcement-module--card-img-top--0Slhx";
export var cardLink = "announcement-module--card-link--k5Qly";
export var cardSubtitle = "announcement-module--card-subtitle--epdgH";
export var cardText = "announcement-module--card-text---F6YE";
export var cardTitle = "announcement-module--card-title--MWer-";
export var carousel = "announcement-module--carousel--T09ph";
export var carouselCaption = "announcement-module--carousel-caption--+Hh+P";
export var carouselControlNext = "announcement-module--carousel-control-next--BaC6e";
export var carouselControlNextIcon = "announcement-module--carousel-control-next-icon--FqtLl";
export var carouselControlPrev = "announcement-module--carousel-control-prev--zvnO0";
export var carouselControlPrevIcon = "announcement-module--carousel-control-prev-icon--M3GnU";
export var carouselDark = "announcement-module--carousel-dark--TeJa7";
export var carouselFade = "announcement-module--carousel-fade--qdNPx";
export var carouselIndicators = "announcement-module--carousel-indicators--JWmOz";
export var carouselInner = "announcement-module--carousel-inner--GVSJL";
export var carouselItem = "announcement-module--carousel-item--u91Jv";
export var carouselItemEnd = "announcement-module--carousel-item-end--nShgT";
export var carouselItemNext = "announcement-module--carousel-item-next--uwnww";
export var carouselItemPrev = "announcement-module--carousel-item-prev--ztCCN";
export var carouselItemStart = "announcement-module--carousel-item-start--8H+0d";
export var clearfix = "announcement-module--clearfix--jWTPV";
export var col = "announcement-module--col--IzbMW";
export var col1 = "announcement-module--col-1--Bim7D";
export var col10 = "announcement-module--col-10--GAz+Z";
export var col11 = "announcement-module--col-11--Fa7Sy";
export var col12 = "announcement-module--col-12--PrKDJ";
export var col2 = "announcement-module--col-2--c0wJ8";
export var col3 = "announcement-module--col-3--jXwPA";
export var col4 = "announcement-module--col-4--idz5D";
export var col5 = "announcement-module--col-5--x4gyv";
export var col6 = "announcement-module--col-6--kdjHl";
export var col7 = "announcement-module--col-7--aXYKA";
export var col8 = "announcement-module--col-8--viiG1";
export var col9 = "announcement-module--col-9--AFGUD";
export var colAuto = "announcement-module--col-auto--6TJS6";
export var colFormLabel = "announcement-module--col-form-label--GzI3h";
export var colFormLabelLg = "announcement-module--col-form-label-lg--dBAPo";
export var colFormLabelSm = "announcement-module--col-form-label-sm--9Nvx3";
export var colLg = "announcement-module--col-lg--YTp9h";
export var colLg1 = "announcement-module--col-lg-1--M0Wp1";
export var colLg10 = "announcement-module--col-lg-10--Im8i5";
export var colLg11 = "announcement-module--col-lg-11--7CwUi";
export var colLg12 = "announcement-module--col-lg-12--LsgCg";
export var colLg2 = "announcement-module--col-lg-2--7y8Dm";
export var colLg3 = "announcement-module--col-lg-3--X1qkC";
export var colLg4 = "announcement-module--col-lg-4--zfToC";
export var colLg5 = "announcement-module--col-lg-5--V3nPl";
export var colLg6 = "announcement-module--col-lg-6--kj5cz";
export var colLg7 = "announcement-module--col-lg-7--2JrXf";
export var colLg8 = "announcement-module--col-lg-8--bictb";
export var colLg9 = "announcement-module--col-lg-9--VUIEj";
export var colLgAuto = "announcement-module--col-lg-auto--j9Qdg";
export var colMd = "announcement-module--col-md--O+OtJ";
export var colMd1 = "announcement-module--col-md-1--53arG";
export var colMd10 = "announcement-module--col-md-10--D5YGC";
export var colMd11 = "announcement-module--col-md-11--4kRV9";
export var colMd12 = "announcement-module--col-md-12--5Vdmu";
export var colMd2 = "announcement-module--col-md-2--Ne22V";
export var colMd3 = "announcement-module--col-md-3--djG8U";
export var colMd4 = "announcement-module--col-md-4--7e6fz";
export var colMd5 = "announcement-module--col-md-5--W1hTJ";
export var colMd6 = "announcement-module--col-md-6--4lVeC";
export var colMd7 = "announcement-module--col-md-7--1BO1l";
export var colMd8 = "announcement-module--col-md-8--lA+6i";
export var colMd9 = "announcement-module--col-md-9--o1nYW";
export var colMdAuto = "announcement-module--col-md-auto--Z7uLB";
export var colSm = "announcement-module--col-sm--XY+SB";
export var colSm1 = "announcement-module--col-sm-1--jIcvc";
export var colSm10 = "announcement-module--col-sm-10--WjZFZ";
export var colSm11 = "announcement-module--col-sm-11--Zc9oO";
export var colSm12 = "announcement-module--col-sm-12--8Szcp";
export var colSm2 = "announcement-module--col-sm-2--ZIYVU";
export var colSm3 = "announcement-module--col-sm-3--yyIkG";
export var colSm4 = "announcement-module--col-sm-4--pa2KL";
export var colSm5 = "announcement-module--col-sm-5--Sdb8-";
export var colSm6 = "announcement-module--col-sm-6--ee1n8";
export var colSm7 = "announcement-module--col-sm-7--epACn";
export var colSm8 = "announcement-module--col-sm-8--DlqAf";
export var colSm9 = "announcement-module--col-sm-9--Z-JIa";
export var colSmAuto = "announcement-module--col-sm-auto--xQSpx";
export var colXl = "announcement-module--col-xl--R1+-Q";
export var colXl1 = "announcement-module--col-xl-1--wznYP";
export var colXl10 = "announcement-module--col-xl-10--TJe4R";
export var colXl11 = "announcement-module--col-xl-11--9eW6k";
export var colXl12 = "announcement-module--col-xl-12--hOUln";
export var colXl2 = "announcement-module--col-xl-2--iu2H7";
export var colXl3 = "announcement-module--col-xl-3--mS8fz";
export var colXl4 = "announcement-module--col-xl-4--wbhUn";
export var colXl5 = "announcement-module--col-xl-5--Gxhfp";
export var colXl6 = "announcement-module--col-xl-6--yTtJq";
export var colXl7 = "announcement-module--col-xl-7--Mq1Uu";
export var colXl8 = "announcement-module--col-xl-8--jEruP";
export var colXl9 = "announcement-module--col-xl-9--WGcEy";
export var colXlAuto = "announcement-module--col-xl-auto--z0qJo";
export var colXxl = "announcement-module--col-xxl--YQISe";
export var colXxl1 = "announcement-module--col-xxl-1--594Fn";
export var colXxl10 = "announcement-module--col-xxl-10--IL+vV";
export var colXxl11 = "announcement-module--col-xxl-11--C-MfP";
export var colXxl12 = "announcement-module--col-xxl-12--lNLWq";
export var colXxl2 = "announcement-module--col-xxl-2--yO5YO";
export var colXxl3 = "announcement-module--col-xxl-3--7t4kk";
export var colXxl4 = "announcement-module--col-xxl-4--GXz3R";
export var colXxl5 = "announcement-module--col-xxl-5--JAK+A";
export var colXxl6 = "announcement-module--col-xxl-6--h891C";
export var colXxl7 = "announcement-module--col-xxl-7---OWIu";
export var colXxl8 = "announcement-module--col-xxl-8--16-OS";
export var colXxl9 = "announcement-module--col-xxl-9--keDmD";
export var colXxlAuto = "announcement-module--col-xxl-auto--XvXRa";
export var collapse = "announcement-module--collapse---Xqp4";
export var collapseHorizontal = "announcement-module--collapse-horizontal--YPYQo";
export var collapsed = "announcement-module--collapsed--Ph0b6";
export var collapsing = "announcement-module--collapsing--qScJh";
export var container = "announcement-module--container--mE3kt";
export var containerFluid = "announcement-module--container-fluid--i33Ko";
export var containerLg = "announcement-module--container-lg--R9n6H";
export var containerMd = "announcement-module--container-md--P1WH7";
export var containerSm = "announcement-module--container-sm--BL7TC";
export var containerXl = "announcement-module--container-xl--YRdy4";
export var containerXxl = "announcement-module--container-xxl--ja7dL";
export var dBlock = "announcement-module--d-block--OwOym";
export var dFlex = "announcement-module--d-flex--210Md";
export var dGrid = "announcement-module--d-grid--hRUcH";
export var dInline = "announcement-module--d-inline--N-Ckb";
export var dInlineBlock = "announcement-module--d-inline-block--kgyqZ";
export var dInlineFlex = "announcement-module--d-inline-flex--3jKSL";
export var dLgBlock = "announcement-module--d-lg-block--JYTjM";
export var dLgFlex = "announcement-module--d-lg-flex--e9GFS";
export var dLgGrid = "announcement-module--d-lg-grid--Nf3Gh";
export var dLgInline = "announcement-module--d-lg-inline--wOAN2";
export var dLgInlineBlock = "announcement-module--d-lg-inline-block--ZU474";
export var dLgInlineFlex = "announcement-module--d-lg-inline-flex--AckOZ";
export var dLgNone = "announcement-module--d-lg-none--NkWWa";
export var dLgTable = "announcement-module--d-lg-table--gjERR";
export var dLgTableCell = "announcement-module--d-lg-table-cell--jo7gV";
export var dLgTableRow = "announcement-module--d-lg-table-row--CUK1D";
export var dMdBlock = "announcement-module--d-md-block--4fCHA";
export var dMdFlex = "announcement-module--d-md-flex--MGHj7";
export var dMdGrid = "announcement-module--d-md-grid--Y3yqL";
export var dMdInline = "announcement-module--d-md-inline--VFUNo";
export var dMdInlineBlock = "announcement-module--d-md-inline-block--AqZDh";
export var dMdInlineFlex = "announcement-module--d-md-inline-flex--6nlNY";
export var dMdNone = "announcement-module--d-md-none--qKt2Q";
export var dMdTable = "announcement-module--d-md-table--QV525";
export var dMdTableCell = "announcement-module--d-md-table-cell--20mWM";
export var dMdTableRow = "announcement-module--d-md-table-row--S5Sqn";
export var dNone = "announcement-module--d-none--jVlu-";
export var dPrintBlock = "announcement-module--d-print-block--nJS0z";
export var dPrintFlex = "announcement-module--d-print-flex--QgNPR";
export var dPrintGrid = "announcement-module--d-print-grid--JeYUu";
export var dPrintInline = "announcement-module--d-print-inline---J6a+";
export var dPrintInlineBlock = "announcement-module--d-print-inline-block--7diUd";
export var dPrintInlineFlex = "announcement-module--d-print-inline-flex--JzYD+";
export var dPrintNone = "announcement-module--d-print-none--FpGcE";
export var dPrintTable = "announcement-module--d-print-table--BkYn6";
export var dPrintTableCell = "announcement-module--d-print-table-cell--a2jeq";
export var dPrintTableRow = "announcement-module--d-print-table-row--UbUG6";
export var dSmBlock = "announcement-module--d-sm-block--HRvx1";
export var dSmFlex = "announcement-module--d-sm-flex--QGLan";
export var dSmGrid = "announcement-module--d-sm-grid--95Par";
export var dSmInline = "announcement-module--d-sm-inline--P2dw8";
export var dSmInlineBlock = "announcement-module--d-sm-inline-block--bxSXz";
export var dSmInlineFlex = "announcement-module--d-sm-inline-flex--+2T7x";
export var dSmNone = "announcement-module--d-sm-none--5u+AH";
export var dSmTable = "announcement-module--d-sm-table--BuHly";
export var dSmTableCell = "announcement-module--d-sm-table-cell--1KfU+";
export var dSmTableRow = "announcement-module--d-sm-table-row--irVEG";
export var dTable = "announcement-module--d-table--e8qSZ";
export var dTableCell = "announcement-module--d-table-cell--wCzoQ";
export var dTableRow = "announcement-module--d-table-row--7xZqJ";
export var dXlBlock = "announcement-module--d-xl-block--tl40S";
export var dXlFlex = "announcement-module--d-xl-flex--EJqhM";
export var dXlGrid = "announcement-module--d-xl-grid--YQF8F";
export var dXlInline = "announcement-module--d-xl-inline--6TSIW";
export var dXlInlineBlock = "announcement-module--d-xl-inline-block--O6nSY";
export var dXlInlineFlex = "announcement-module--d-xl-inline-flex--+esIA";
export var dXlNone = "announcement-module--d-xl-none--gjkMS";
export var dXlTable = "announcement-module--d-xl-table--GMdUu";
export var dXlTableCell = "announcement-module--d-xl-table-cell--VB+dE";
export var dXlTableRow = "announcement-module--d-xl-table-row--Qq61H";
export var dXxlBlock = "announcement-module--d-xxl-block--hwhOb";
export var dXxlFlex = "announcement-module--d-xxl-flex--yJLmS";
export var dXxlGrid = "announcement-module--d-xxl-grid--FjTE4";
export var dXxlInline = "announcement-module--d-xxl-inline--dKkGT";
export var dXxlInlineBlock = "announcement-module--d-xxl-inline-block--3eil+";
export var dXxlInlineFlex = "announcement-module--d-xxl-inline-flex--Hn7PP";
export var dXxlNone = "announcement-module--d-xxl-none--vsbr4";
export var dXxlTable = "announcement-module--d-xxl-table--EfMy+";
export var dXxlTableCell = "announcement-module--d-xxl-table-cell--DE2fb";
export var dXxlTableRow = "announcement-module--d-xxl-table-row--UI+rO";
export var disabled = "announcement-module--disabled--b68DF";
export var display1 = "announcement-module--display-1--4g7wD";
export var display2 = "announcement-module--display-2--GXNMG";
export var display3 = "announcement-module--display-3--LpyXZ";
export var display4 = "announcement-module--display-4--i9S+r";
export var display5 = "announcement-module--display-5--g9enu";
export var display6 = "announcement-module--display-6--1XYKK";
export var dropdown = "announcement-module--dropdown--Ia8s9";
export var dropdownDivider = "announcement-module--dropdown-divider--rXk7X";
export var dropdownHeader = "announcement-module--dropdown-header--3V5pk";
export var dropdownItem = "announcement-module--dropdown-item--Ms7Wc";
export var dropdownItemText = "announcement-module--dropdown-item-text--5ApQW";
export var dropdownMenu = "announcement-module--dropdown-menu--G012q";
export var dropdownMenuDark = "announcement-module--dropdown-menu-dark--vl7Ol";
export var dropdownMenuEnd = "announcement-module--dropdown-menu-end--wR2KW";
export var dropdownMenuLgEnd = "announcement-module--dropdown-menu-lg-end--1HKJR";
export var dropdownMenuLgStart = "announcement-module--dropdown-menu-lg-start--e7Qp2";
export var dropdownMenuMdEnd = "announcement-module--dropdown-menu-md-end--Hzm-G";
export var dropdownMenuMdStart = "announcement-module--dropdown-menu-md-start--Ql9A2";
export var dropdownMenuSmEnd = "announcement-module--dropdown-menu-sm-end--fPSxR";
export var dropdownMenuSmStart = "announcement-module--dropdown-menu-sm-start--LPoJh";
export var dropdownMenuStart = "announcement-module--dropdown-menu-start--a0Zg5";
export var dropdownMenuXlEnd = "announcement-module--dropdown-menu-xl-end--IX875";
export var dropdownMenuXlStart = "announcement-module--dropdown-menu-xl-start--iiGhc";
export var dropdownMenuXxlEnd = "announcement-module--dropdown-menu-xxl-end--5zW5Z";
export var dropdownMenuXxlStart = "announcement-module--dropdown-menu-xxl-start--2qwpu";
export var dropdownToggle = "announcement-module--dropdown-toggle--YWC1D";
export var dropdownToggleSplit = "announcement-module--dropdown-toggle-split--F5Hbo";
export var dropend = "announcement-module--dropend--i8uY+";
export var dropstart = "announcement-module--dropstart--8FAdt";
export var dropup = "announcement-module--dropup--U2quL";
export var end0 = "announcement-module--end-0--L-xLI";
export var end100 = "announcement-module--end-100--S+PF+";
export var end50 = "announcement-module--end-50--tIfjt";
export var fade = "announcement-module--fade--6rCDl";
export var figure = "announcement-module--figure--LE8Fz";
export var figureCaption = "announcement-module--figure-caption--vYW5e";
export var figureImg = "announcement-module--figure-img--yk1in";
export var fixedBottom = "announcement-module--fixed-bottom--dalgu";
export var fixedTop = "announcement-module--fixed-top--4Aa7A";
export var flexColumn = "announcement-module--flex-column--SFCfS";
export var flexColumnReverse = "announcement-module--flex-column-reverse--ZmDPi";
export var flexFill = "announcement-module--flex-fill--fxpyv";
export var flexGrow0 = "announcement-module--flex-grow-0--t0kQa";
export var flexGrow1 = "announcement-module--flex-grow-1--hEwgc";
export var flexLgColumn = "announcement-module--flex-lg-column--2HoxT";
export var flexLgColumnReverse = "announcement-module--flex-lg-column-reverse--Vqao2";
export var flexLgFill = "announcement-module--flex-lg-fill--S5Rej";
export var flexLgGrow0 = "announcement-module--flex-lg-grow-0--abDzj";
export var flexLgGrow1 = "announcement-module--flex-lg-grow-1--VVy+z";
export var flexLgNowrap = "announcement-module--flex-lg-nowrap--HwpgP";
export var flexLgRow = "announcement-module--flex-lg-row--XXzCo";
export var flexLgRowReverse = "announcement-module--flex-lg-row-reverse--XvAYq";
export var flexLgShrink0 = "announcement-module--flex-lg-shrink-0--muCll";
export var flexLgShrink1 = "announcement-module--flex-lg-shrink-1--97NcJ";
export var flexLgWrap = "announcement-module--flex-lg-wrap--Fzfkl";
export var flexLgWrapReverse = "announcement-module--flex-lg-wrap-reverse--MX00J";
export var flexMdColumn = "announcement-module--flex-md-column--n2znQ";
export var flexMdColumnReverse = "announcement-module--flex-md-column-reverse--1oY9e";
export var flexMdFill = "announcement-module--flex-md-fill--e9tTW";
export var flexMdGrow0 = "announcement-module--flex-md-grow-0--4sSMy";
export var flexMdGrow1 = "announcement-module--flex-md-grow-1--kBoxl";
export var flexMdNowrap = "announcement-module--flex-md-nowrap--ggPsp";
export var flexMdRow = "announcement-module--flex-md-row--jdvz8";
export var flexMdRowReverse = "announcement-module--flex-md-row-reverse--ayoU3";
export var flexMdShrink0 = "announcement-module--flex-md-shrink-0--ESOAQ";
export var flexMdShrink1 = "announcement-module--flex-md-shrink-1--Bfv00";
export var flexMdWrap = "announcement-module--flex-md-wrap--HYdEG";
export var flexMdWrapReverse = "announcement-module--flex-md-wrap-reverse--89CPZ";
export var flexNowrap = "announcement-module--flex-nowrap--6OCQt";
export var flexRow = "announcement-module--flex-row--QT7jO";
export var flexRowReverse = "announcement-module--flex-row-reverse--dNm9+";
export var flexShrink0 = "announcement-module--flex-shrink-0--zRjH3";
export var flexShrink1 = "announcement-module--flex-shrink-1--okOe0";
export var flexSmColumn = "announcement-module--flex-sm-column--xOrtb";
export var flexSmColumnReverse = "announcement-module--flex-sm-column-reverse--GbQJl";
export var flexSmFill = "announcement-module--flex-sm-fill--NB-Al";
export var flexSmGrow0 = "announcement-module--flex-sm-grow-0--yXvwy";
export var flexSmGrow1 = "announcement-module--flex-sm-grow-1--QG5rj";
export var flexSmNowrap = "announcement-module--flex-sm-nowrap--FjL-A";
export var flexSmRow = "announcement-module--flex-sm-row--Kd2eH";
export var flexSmRowReverse = "announcement-module--flex-sm-row-reverse--j-N9J";
export var flexSmShrink0 = "announcement-module--flex-sm-shrink-0--L-fsY";
export var flexSmShrink1 = "announcement-module--flex-sm-shrink-1--pe-nS";
export var flexSmWrap = "announcement-module--flex-sm-wrap--OWekw";
export var flexSmWrapReverse = "announcement-module--flex-sm-wrap-reverse--aBYIr";
export var flexWrap = "announcement-module--flex-wrap--mrtWV";
export var flexWrapReverse = "announcement-module--flex-wrap-reverse--WEEBP";
export var flexXlColumn = "announcement-module--flex-xl-column--7QFng";
export var flexXlColumnReverse = "announcement-module--flex-xl-column-reverse--JJARt";
export var flexXlFill = "announcement-module--flex-xl-fill--UV5C9";
export var flexXlGrow0 = "announcement-module--flex-xl-grow-0--Nb8ZE";
export var flexXlGrow1 = "announcement-module--flex-xl-grow-1--lN60v";
export var flexXlNowrap = "announcement-module--flex-xl-nowrap--vAOc+";
export var flexXlRow = "announcement-module--flex-xl-row--6t09t";
export var flexXlRowReverse = "announcement-module--flex-xl-row-reverse--C-X7V";
export var flexXlShrink0 = "announcement-module--flex-xl-shrink-0--1v7sM";
export var flexXlShrink1 = "announcement-module--flex-xl-shrink-1--bBC7z";
export var flexXlWrap = "announcement-module--flex-xl-wrap--0jK2h";
export var flexXlWrapReverse = "announcement-module--flex-xl-wrap-reverse--t9EpY";
export var flexXxlColumn = "announcement-module--flex-xxl-column--DNzEe";
export var flexXxlColumnReverse = "announcement-module--flex-xxl-column-reverse--Y8gne";
export var flexXxlFill = "announcement-module--flex-xxl-fill--Nmiol";
export var flexXxlGrow0 = "announcement-module--flex-xxl-grow-0--ISx8p";
export var flexXxlGrow1 = "announcement-module--flex-xxl-grow-1--3hjWz";
export var flexXxlNowrap = "announcement-module--flex-xxl-nowrap--TGhew";
export var flexXxlRow = "announcement-module--flex-xxl-row--yaCo9";
export var flexXxlRowReverse = "announcement-module--flex-xxl-row-reverse--qxwvH";
export var flexXxlShrink0 = "announcement-module--flex-xxl-shrink-0--w-9gD";
export var flexXxlShrink1 = "announcement-module--flex-xxl-shrink-1--cHzSx";
export var flexXxlWrap = "announcement-module--flex-xxl-wrap--w2lAb";
export var flexXxlWrapReverse = "announcement-module--flex-xxl-wrap-reverse--rqnKJ";
export var floatEnd = "announcement-module--float-end--Xcg8Z";
export var floatLgEnd = "announcement-module--float-lg-end--yRz14";
export var floatLgNone = "announcement-module--float-lg-none--vBToW";
export var floatLgStart = "announcement-module--float-lg-start--50jh2";
export var floatMdEnd = "announcement-module--float-md-end--71ntY";
export var floatMdNone = "announcement-module--float-md-none--y15cE";
export var floatMdStart = "announcement-module--float-md-start--Nt60M";
export var floatNone = "announcement-module--float-none--JWDOu";
export var floatSmEnd = "announcement-module--float-sm-end--5tTJH";
export var floatSmNone = "announcement-module--float-sm-none--uFF4H";
export var floatSmStart = "announcement-module--float-sm-start--oBpd4";
export var floatStart = "announcement-module--float-start--HHVGX";
export var floatXlEnd = "announcement-module--float-xl-end--NDpfa";
export var floatXlNone = "announcement-module--float-xl-none--28xtr";
export var floatXlStart = "announcement-module--float-xl-start--p7cqs";
export var floatXxlEnd = "announcement-module--float-xxl-end--IdVd9";
export var floatXxlNone = "announcement-module--float-xxl-none--tWV6M";
export var floatXxlStart = "announcement-module--float-xxl-start--Hrozx";
export var fontMonospace = "announcement-module--font-monospace--PmjPO";
export var formCheck = "announcement-module--form-check--nVP0n";
export var formCheckInline = "announcement-module--form-check-inline--vvmVy";
export var formCheckInput = "announcement-module--form-check-input--0+9Q6";
export var formCheckLabel = "announcement-module--form-check-label--w1g9w";
export var formControl = "announcement-module--form-control--Al140";
export var formControlColor = "announcement-module--form-control-color--odyKS";
export var formControlLg = "announcement-module--form-control-lg--kwn+Y";
export var formControlPlaintext = "announcement-module--form-control-plaintext--VTVoQ";
export var formControlSm = "announcement-module--form-control-sm--LWTzA";
export var formFloating = "announcement-module--form-floating--Mw19W";
export var formLabel = "announcement-module--form-label--UrocD";
export var formRange = "announcement-module--form-range--UPJfC";
export var formSelect = "announcement-module--form-select--A-v0e";
export var formSelectLg = "announcement-module--form-select-lg--5H1XO";
export var formSelectSm = "announcement-module--form-select-sm--KLe8A";
export var formSwitch = "announcement-module--form-switch--dgSdm";
export var formText = "announcement-module--form-text--creKY";
export var fs1 = "announcement-module--fs-1--lJuIF";
export var fs2 = "announcement-module--fs-2--+Dh49";
export var fs3 = "announcement-module--fs-3--OzDwj";
export var fs4 = "announcement-module--fs-4--MSqHA";
export var fs5 = "announcement-module--fs-5--vtd7j";
export var fs6 = "announcement-module--fs-6--HpyMX";
export var fstItalic = "announcement-module--fst-italic--A8fY2";
export var fstNormal = "announcement-module--fst-normal--44vhP";
export var fwBold = "announcement-module--fw-bold--PlMA1";
export var fwBolder = "announcement-module--fw-bolder--2CSfm";
export var fwLight = "announcement-module--fw-light--oYFok";
export var fwLighter = "announcement-module--fw-lighter--s9Xjb";
export var fwNormal = "announcement-module--fw-normal--RQH21";
export var g0 = "announcement-module--g-0--Adbql";
export var g1 = "announcement-module--g-1--bHFyC";
export var g2 = "announcement-module--g-2--zf6Ea";
export var g3 = "announcement-module--g-3--vM4j5";
export var g4 = "announcement-module--g-4--WxC7L";
export var g5 = "announcement-module--g-5--NNgaU";
export var gLg0 = "announcement-module--g-lg-0--lQGdn";
export var gLg1 = "announcement-module--g-lg-1--g70ql";
export var gLg2 = "announcement-module--g-lg-2--hSmbG";
export var gLg3 = "announcement-module--g-lg-3--GqEgX";
export var gLg4 = "announcement-module--g-lg-4--ZwhNx";
export var gLg5 = "announcement-module--g-lg-5--qtMb5";
export var gMd0 = "announcement-module--g-md-0--0BfLB";
export var gMd1 = "announcement-module--g-md-1--OaRzh";
export var gMd2 = "announcement-module--g-md-2--lL4Kx";
export var gMd3 = "announcement-module--g-md-3--bQetf";
export var gMd4 = "announcement-module--g-md-4--Hqfen";
export var gMd5 = "announcement-module--g-md-5--9jieB";
export var gSm0 = "announcement-module--g-sm-0--Tz4Ra";
export var gSm1 = "announcement-module--g-sm-1--bHvR4";
export var gSm2 = "announcement-module--g-sm-2--rNJM0";
export var gSm3 = "announcement-module--g-sm-3--QRBBe";
export var gSm4 = "announcement-module--g-sm-4--29PLT";
export var gSm5 = "announcement-module--g-sm-5--k0Y5j";
export var gXl0 = "announcement-module--g-xl-0--tCpXb";
export var gXl1 = "announcement-module--g-xl-1--umXam";
export var gXl2 = "announcement-module--g-xl-2--zJS79";
export var gXl3 = "announcement-module--g-xl-3--V3Gx1";
export var gXl4 = "announcement-module--g-xl-4--9XqZU";
export var gXl5 = "announcement-module--g-xl-5--X0cDG";
export var gXxl0 = "announcement-module--g-xxl-0--ojWOi";
export var gXxl1 = "announcement-module--g-xxl-1--F2hmP";
export var gXxl2 = "announcement-module--g-xxl-2--fkmMA";
export var gXxl3 = "announcement-module--g-xxl-3--1SqIw";
export var gXxl4 = "announcement-module--g-xxl-4--sPk-3";
export var gXxl5 = "announcement-module--g-xxl-5--y7BIH";
export var gap0 = "announcement-module--gap-0--Lh1-8";
export var gap1 = "announcement-module--gap-1--RoQA2";
export var gap2 = "announcement-module--gap-2--dVirE";
export var gap3 = "announcement-module--gap-3--DIg0g";
export var gap4 = "announcement-module--gap-4--YTpcc";
export var gap5 = "announcement-module--gap-5--5d8Iw";
export var gapLg0 = "announcement-module--gap-lg-0--8rvar";
export var gapLg1 = "announcement-module--gap-lg-1--pEQfY";
export var gapLg2 = "announcement-module--gap-lg-2--7dvWW";
export var gapLg3 = "announcement-module--gap-lg-3--8UdF7";
export var gapLg4 = "announcement-module--gap-lg-4--YhBs0";
export var gapLg5 = "announcement-module--gap-lg-5--Rsgut";
export var gapMd0 = "announcement-module--gap-md-0---vW9G";
export var gapMd1 = "announcement-module--gap-md-1--W2WRI";
export var gapMd2 = "announcement-module--gap-md-2--puftr";
export var gapMd3 = "announcement-module--gap-md-3--NC7gO";
export var gapMd4 = "announcement-module--gap-md-4--84sxh";
export var gapMd5 = "announcement-module--gap-md-5--gvFkZ";
export var gapSm0 = "announcement-module--gap-sm-0--7JqE6";
export var gapSm1 = "announcement-module--gap-sm-1---hun9";
export var gapSm2 = "announcement-module--gap-sm-2--5mzhF";
export var gapSm3 = "announcement-module--gap-sm-3--ibcU4";
export var gapSm4 = "announcement-module--gap-sm-4---S9jc";
export var gapSm5 = "announcement-module--gap-sm-5--KXDLZ";
export var gapXl0 = "announcement-module--gap-xl-0--7c+68";
export var gapXl1 = "announcement-module--gap-xl-1--IIGhh";
export var gapXl2 = "announcement-module--gap-xl-2--Qpc2J";
export var gapXl3 = "announcement-module--gap-xl-3--ZCeZM";
export var gapXl4 = "announcement-module--gap-xl-4--hTXHw";
export var gapXl5 = "announcement-module--gap-xl-5--HM40v";
export var gapXxl0 = "announcement-module--gap-xxl-0--orG-K";
export var gapXxl1 = "announcement-module--gap-xxl-1--u8yot";
export var gapXxl2 = "announcement-module--gap-xxl-2--L2G3N";
export var gapXxl3 = "announcement-module--gap-xxl-3--3bIrY";
export var gapXxl4 = "announcement-module--gap-xxl-4--78rz5";
export var gapXxl5 = "announcement-module--gap-xxl-5---u73P";
export var gx0 = "announcement-module--gx-0--nzFc1";
export var gx1 = "announcement-module--gx-1--2qyoe";
export var gx2 = "announcement-module--gx-2--03wDY";
export var gx3 = "announcement-module--gx-3--WzPn1";
export var gx4 = "announcement-module--gx-4--tuhK4";
export var gx5 = "announcement-module--gx-5--I-iHq";
export var gxLg0 = "announcement-module--gx-lg-0---NLsp";
export var gxLg1 = "announcement-module--gx-lg-1--z9S5F";
export var gxLg2 = "announcement-module--gx-lg-2--Ypfn9";
export var gxLg3 = "announcement-module--gx-lg-3--3a2rd";
export var gxLg4 = "announcement-module--gx-lg-4--kuECy";
export var gxLg5 = "announcement-module--gx-lg-5--Ks1pU";
export var gxMd0 = "announcement-module--gx-md-0--OOtst";
export var gxMd1 = "announcement-module--gx-md-1--63uWL";
export var gxMd2 = "announcement-module--gx-md-2--KW6IM";
export var gxMd3 = "announcement-module--gx-md-3--ZAeBQ";
export var gxMd4 = "announcement-module--gx-md-4--9at9-";
export var gxMd5 = "announcement-module--gx-md-5--cKLcz";
export var gxSm0 = "announcement-module--gx-sm-0---BmCF";
export var gxSm1 = "announcement-module--gx-sm-1--2iyLp";
export var gxSm2 = "announcement-module--gx-sm-2--IgJnO";
export var gxSm3 = "announcement-module--gx-sm-3--7eYW9";
export var gxSm4 = "announcement-module--gx-sm-4--DRoA2";
export var gxSm5 = "announcement-module--gx-sm-5--f4KUb";
export var gxXl0 = "announcement-module--gx-xl-0--7M8CR";
export var gxXl1 = "announcement-module--gx-xl-1--hnacm";
export var gxXl2 = "announcement-module--gx-xl-2--+ZvYZ";
export var gxXl3 = "announcement-module--gx-xl-3--UsT17";
export var gxXl4 = "announcement-module--gx-xl-4--j1ywS";
export var gxXl5 = "announcement-module--gx-xl-5---mP1W";
export var gxXxl0 = "announcement-module--gx-xxl-0--26yCy";
export var gxXxl1 = "announcement-module--gx-xxl-1--gHYC4";
export var gxXxl2 = "announcement-module--gx-xxl-2--1XSwF";
export var gxXxl3 = "announcement-module--gx-xxl-3--qP32G";
export var gxXxl4 = "announcement-module--gx-xxl-4--7LwXP";
export var gxXxl5 = "announcement-module--gx-xxl-5--sKqcV";
export var gy0 = "announcement-module--gy-0--StKx0";
export var gy1 = "announcement-module--gy-1--br51W";
export var gy2 = "announcement-module--gy-2--cZk0c";
export var gy3 = "announcement-module--gy-3--49L32";
export var gy4 = "announcement-module--gy-4--7XOTc";
export var gy5 = "announcement-module--gy-5--CCNGU";
export var gyLg0 = "announcement-module--gy-lg-0--0uXaX";
export var gyLg1 = "announcement-module--gy-lg-1--0dESZ";
export var gyLg2 = "announcement-module--gy-lg-2--25keU";
export var gyLg3 = "announcement-module--gy-lg-3--eFLuW";
export var gyLg4 = "announcement-module--gy-lg-4--CVmnL";
export var gyLg5 = "announcement-module--gy-lg-5--KfB0T";
export var gyMd0 = "announcement-module--gy-md-0--vKWXN";
export var gyMd1 = "announcement-module--gy-md-1--Tk8LW";
export var gyMd2 = "announcement-module--gy-md-2--a3Okv";
export var gyMd3 = "announcement-module--gy-md-3--Weo4k";
export var gyMd4 = "announcement-module--gy-md-4--uH7Ok";
export var gyMd5 = "announcement-module--gy-md-5--c84xa";
export var gySm0 = "announcement-module--gy-sm-0--Ov+2V";
export var gySm1 = "announcement-module--gy-sm-1--9SXZl";
export var gySm2 = "announcement-module--gy-sm-2--JjYgJ";
export var gySm3 = "announcement-module--gy-sm-3--0EAhw";
export var gySm4 = "announcement-module--gy-sm-4--cbDHa";
export var gySm5 = "announcement-module--gy-sm-5--JWrQZ";
export var gyXl0 = "announcement-module--gy-xl-0--4L4Ax";
export var gyXl1 = "announcement-module--gy-xl-1--Q1Iij";
export var gyXl2 = "announcement-module--gy-xl-2--Z1CQY";
export var gyXl3 = "announcement-module--gy-xl-3--M62O5";
export var gyXl4 = "announcement-module--gy-xl-4--N4Y7y";
export var gyXl5 = "announcement-module--gy-xl-5--BqnVo";
export var gyXxl0 = "announcement-module--gy-xxl-0--ZPiEF";
export var gyXxl1 = "announcement-module--gy-xxl-1--StqRX";
export var gyXxl2 = "announcement-module--gy-xxl-2--Og8he";
export var gyXxl3 = "announcement-module--gy-xxl-3--W6Hmm";
export var gyXxl4 = "announcement-module--gy-xxl-4--UT2AJ";
export var gyXxl5 = "announcement-module--gy-xxl-5--lQfcB";
export var h1 = "announcement-module--h1--Hzg2D";
export var h100 = "announcement-module--h-100--PJB5i";
export var h2 = "announcement-module--h2--4DjM8";
export var h25 = "announcement-module--h-25--LllCM";
export var h3 = "announcement-module--h3--CGV6y";
export var h4 = "announcement-module--h4--UK4Sm";
export var h5 = "announcement-module--h5--UzaLX";
export var h50 = "announcement-module--h-50--1bKtj";
export var h6 = "announcement-module--h6--5ETjM";
export var h75 = "announcement-module--h-75--Yvyso";
export var hAuto = "announcement-module--h-auto--Q3q7X";
export var hasValidation = "announcement-module--has-validation--A1Wrs";
export var hstack = "announcement-module--hstack--bRO4y";
export var imgFluid = "announcement-module--img-fluid--GcEsr";
export var imgThumbnail = "announcement-module--img-thumbnail--jxZsI";
export var initialism = "announcement-module--initialism--hECTc";
export var inputGroup = "announcement-module--input-group--hamdz";
export var inputGroupLg = "announcement-module--input-group-lg--m1e1b";
export var inputGroupSm = "announcement-module--input-group-sm--gy5QS";
export var inputGroupText = "announcement-module--input-group-text--8z+YQ";
export var invalidFeedback = "announcement-module--invalid-feedback--4bG1s";
export var invalidTooltip = "announcement-module--invalid-tooltip--DiIO5";
export var invisible = "announcement-module--invisible--Ihhc2";
export var isInvalid = "announcement-module--is-invalid--6Up2y";
export var isValid = "announcement-module--is-valid--0ItMi";
export var justifyContentAround = "announcement-module--justify-content-around--WVA3r";
export var justifyContentBetween = "announcement-module--justify-content-between--SvIOi";
export var justifyContentCenter = "announcement-module--justify-content-center--1EdPZ";
export var justifyContentEnd = "announcement-module--justify-content-end--jicjj";
export var justifyContentEvenly = "announcement-module--justify-content-evenly--fFhtY";
export var justifyContentLgAround = "announcement-module--justify-content-lg-around--5DUZ2";
export var justifyContentLgBetween = "announcement-module--justify-content-lg-between--kb6eW";
export var justifyContentLgCenter = "announcement-module--justify-content-lg-center--yKYRA";
export var justifyContentLgEnd = "announcement-module--justify-content-lg-end--cGSXn";
export var justifyContentLgEvenly = "announcement-module--justify-content-lg-evenly--B8LRS";
export var justifyContentLgStart = "announcement-module--justify-content-lg-start--WNlu2";
export var justifyContentMdAround = "announcement-module--justify-content-md-around--dn4Q0";
export var justifyContentMdBetween = "announcement-module--justify-content-md-between--kf2EX";
export var justifyContentMdCenter = "announcement-module--justify-content-md-center--QtH4f";
export var justifyContentMdEnd = "announcement-module--justify-content-md-end--2zAFK";
export var justifyContentMdEvenly = "announcement-module--justify-content-md-evenly--pGmaZ";
export var justifyContentMdStart = "announcement-module--justify-content-md-start--iP842";
export var justifyContentSmAround = "announcement-module--justify-content-sm-around--Q7omJ";
export var justifyContentSmBetween = "announcement-module--justify-content-sm-between--0f7Ob";
export var justifyContentSmCenter = "announcement-module--justify-content-sm-center--HWuxe";
export var justifyContentSmEnd = "announcement-module--justify-content-sm-end--NsgdO";
export var justifyContentSmEvenly = "announcement-module--justify-content-sm-evenly--fE2rT";
export var justifyContentSmStart = "announcement-module--justify-content-sm-start--5zKwa";
export var justifyContentStart = "announcement-module--justify-content-start--Bq3w7";
export var justifyContentXlAround = "announcement-module--justify-content-xl-around--9wq5E";
export var justifyContentXlBetween = "announcement-module--justify-content-xl-between--VeqC2";
export var justifyContentXlCenter = "announcement-module--justify-content-xl-center--4y3Qk";
export var justifyContentXlEnd = "announcement-module--justify-content-xl-end--fIj1n";
export var justifyContentXlEvenly = "announcement-module--justify-content-xl-evenly--fT6Q6";
export var justifyContentXlStart = "announcement-module--justify-content-xl-start--lweQz";
export var justifyContentXxlAround = "announcement-module--justify-content-xxl-around--D0Sd4";
export var justifyContentXxlBetween = "announcement-module--justify-content-xxl-between--H2jqa";
export var justifyContentXxlCenter = "announcement-module--justify-content-xxl-center--RyP+Y";
export var justifyContentXxlEnd = "announcement-module--justify-content-xxl-end--TOQcM";
export var justifyContentXxlEvenly = "announcement-module--justify-content-xxl-evenly--gvduP";
export var justifyContentXxlStart = "announcement-module--justify-content-xxl-start--DEME+";
export var lead = "announcement-module--lead--uC1P9";
export var lh1 = "announcement-module--lh-1--D+IRm";
export var lhBase = "announcement-module--lh-base--uQPRX";
export var lhLg = "announcement-module--lh-lg--yoyFY";
export var lhSm = "announcement-module--lh-sm--gi+YT";
export var linkDanger = "announcement-module--link-danger--DUli2";
export var linkDark = "announcement-module--link-dark--O-7tB";
export var linkInfo = "announcement-module--link-info--0B4Wc";
export var linkLight = "announcement-module--link-light--iiEfQ";
export var linkPrimary = "announcement-module--link-primary--Cg6C5";
export var linkSecondary = "announcement-module--link-secondary--9N1TH";
export var linkSuccess = "announcement-module--link-success--s5mw7";
export var linkWarning = "announcement-module--link-warning--mGMOu";
export var listGroup = "announcement-module--list-group--HA3ew";
export var listGroupFlush = "announcement-module--list-group-flush--uY86H";
export var listGroupHorizontal = "announcement-module--list-group-horizontal--7Pn10";
export var listGroupHorizontalLg = "announcement-module--list-group-horizontal-lg--Y3tZe";
export var listGroupHorizontalMd = "announcement-module--list-group-horizontal-md--dFFDd";
export var listGroupHorizontalSm = "announcement-module--list-group-horizontal-sm--gqTIA";
export var listGroupHorizontalXl = "announcement-module--list-group-horizontal-xl--tyEyg";
export var listGroupHorizontalXxl = "announcement-module--list-group-horizontal-xxl--wtMAr";
export var listGroupItem = "announcement-module--list-group-item--bt2yp";
export var listGroupItemAction = "announcement-module--list-group-item-action--pFLVd";
export var listGroupItemDanger = "announcement-module--list-group-item-danger--BDP8l";
export var listGroupItemDark = "announcement-module--list-group-item-dark--o7ClS";
export var listGroupItemInfo = "announcement-module--list-group-item-info--9w+9f";
export var listGroupItemLight = "announcement-module--list-group-item-light--tpPrL";
export var listGroupItemPrimary = "announcement-module--list-group-item-primary--XZl95";
export var listGroupItemSecondary = "announcement-module--list-group-item-secondary--hk6oc";
export var listGroupItemSuccess = "announcement-module--list-group-item-success--ni2FI";
export var listGroupItemWarning = "announcement-module--list-group-item-warning--3ctU2";
export var listGroupNumbered = "announcement-module--list-group-numbered--7vBWM";
export var listInline = "announcement-module--list-inline--oHOxQ";
export var listInlineItem = "announcement-module--list-inline-item--SI7Gf";
export var listUnstyled = "announcement-module--list-unstyled--ho5a5";
export var m0 = "announcement-module--m-0--+fkGv";
export var m1 = "announcement-module--m-1--ekqFw";
export var m2 = "announcement-module--m-2--h2c2F";
export var m3 = "announcement-module--m-3--23PtT";
export var m4 = "announcement-module--m-4--MqgCq";
export var m5 = "announcement-module--m-5--aniQq";
export var mAuto = "announcement-module--m-auto--CKCdQ";
export var mLg0 = "announcement-module--m-lg-0--LKAsW";
export var mLg1 = "announcement-module--m-lg-1--qKfRm";
export var mLg2 = "announcement-module--m-lg-2--SAOMD";
export var mLg3 = "announcement-module--m-lg-3--dxJUs";
export var mLg4 = "announcement-module--m-lg-4--GrVoF";
export var mLg5 = "announcement-module--m-lg-5--5nFQ0";
export var mLgAuto = "announcement-module--m-lg-auto--UBkAG";
export var mMd0 = "announcement-module--m-md-0--cUc9c";
export var mMd1 = "announcement-module--m-md-1--K1I7d";
export var mMd2 = "announcement-module--m-md-2--+F-sc";
export var mMd3 = "announcement-module--m-md-3--9bp11";
export var mMd4 = "announcement-module--m-md-4--IPCUB";
export var mMd5 = "announcement-module--m-md-5--pIcL0";
export var mMdAuto = "announcement-module--m-md-auto--L9pOr";
export var mSm0 = "announcement-module--m-sm-0--7JYij";
export var mSm1 = "announcement-module--m-sm-1--uLzac";
export var mSm2 = "announcement-module--m-sm-2--FlDCZ";
export var mSm3 = "announcement-module--m-sm-3--FsCT4";
export var mSm4 = "announcement-module--m-sm-4--nT7Vm";
export var mSm5 = "announcement-module--m-sm-5--axQbJ";
export var mSmAuto = "announcement-module--m-sm-auto--XBJMK";
export var mXl0 = "announcement-module--m-xl-0--1PWuS";
export var mXl1 = "announcement-module--m-xl-1--50Ldk";
export var mXl2 = "announcement-module--m-xl-2--oz2Mm";
export var mXl3 = "announcement-module--m-xl-3--C0IuP";
export var mXl4 = "announcement-module--m-xl-4--IMHvA";
export var mXl5 = "announcement-module--m-xl-5--7yORn";
export var mXlAuto = "announcement-module--m-xl-auto--IfZkg";
export var mXxl0 = "announcement-module--m-xxl-0--D0Pa7";
export var mXxl1 = "announcement-module--m-xxl-1--xyNmn";
export var mXxl2 = "announcement-module--m-xxl-2--I+iM1";
export var mXxl3 = "announcement-module--m-xxl-3--SOu7S";
export var mXxl4 = "announcement-module--m-xxl-4--h5uT+";
export var mXxl5 = "announcement-module--m-xxl-5--F4zjR";
export var mXxlAuto = "announcement-module--m-xxl-auto--3EwBX";
export var mark = "announcement-module--mark--IO+sQ";
export var mb0 = "announcement-module--mb-0--i1VjF";
export var mb1 = "announcement-module--mb-1--iWSge";
export var mb2 = "announcement-module--mb-2--EsIQS";
export var mb3 = "announcement-module--mb-3--Lgg4K";
export var mb4 = "announcement-module--mb-4--jGppI";
export var mb5 = "announcement-module--mb-5--pPz-8";
export var mbAuto = "announcement-module--mb-auto---54xS";
export var mbLg0 = "announcement-module--mb-lg-0--GV15-";
export var mbLg1 = "announcement-module--mb-lg-1--hM0Ea";
export var mbLg2 = "announcement-module--mb-lg-2--2MkXf";
export var mbLg3 = "announcement-module--mb-lg-3--MArXG";
export var mbLg4 = "announcement-module--mb-lg-4--uqypV";
export var mbLg5 = "announcement-module--mb-lg-5--Qh203";
export var mbLgAuto = "announcement-module--mb-lg-auto--2ki+3";
export var mbMd0 = "announcement-module--mb-md-0--U8qu4";
export var mbMd1 = "announcement-module--mb-md-1--d7u73";
export var mbMd2 = "announcement-module--mb-md-2--YsM-j";
export var mbMd3 = "announcement-module--mb-md-3--Ge1qB";
export var mbMd4 = "announcement-module--mb-md-4--Rlcuq";
export var mbMd5 = "announcement-module--mb-md-5--MZbg1";
export var mbMdAuto = "announcement-module--mb-md-auto--KsqSR";
export var mbSm0 = "announcement-module--mb-sm-0--dNO4U";
export var mbSm1 = "announcement-module--mb-sm-1--vdEtI";
export var mbSm2 = "announcement-module--mb-sm-2--jrhWj";
export var mbSm3 = "announcement-module--mb-sm-3--6SpK8";
export var mbSm4 = "announcement-module--mb-sm-4--iKvqV";
export var mbSm5 = "announcement-module--mb-sm-5--Z+HWh";
export var mbSmAuto = "announcement-module--mb-sm-auto--TpRZy";
export var mbXl0 = "announcement-module--mb-xl-0--mzUbr";
export var mbXl1 = "announcement-module--mb-xl-1--oZ0CS";
export var mbXl2 = "announcement-module--mb-xl-2--Mtsc1";
export var mbXl3 = "announcement-module--mb-xl-3--DLkIr";
export var mbXl4 = "announcement-module--mb-xl-4--n2XjE";
export var mbXl5 = "announcement-module--mb-xl-5--M5gfb";
export var mbXlAuto = "announcement-module--mb-xl-auto--l+yjH";
export var mbXxl0 = "announcement-module--mb-xxl-0--O4sgz";
export var mbXxl1 = "announcement-module--mb-xxl-1--iLUEW";
export var mbXxl2 = "announcement-module--mb-xxl-2--irC3A";
export var mbXxl3 = "announcement-module--mb-xxl-3--fm3lN";
export var mbXxl4 = "announcement-module--mb-xxl-4--Lck5R";
export var mbXxl5 = "announcement-module--mb-xxl-5--R1HH+";
export var mbXxlAuto = "announcement-module--mb-xxl-auto--+RRoW";
export var me0 = "announcement-module--me-0--dATdM";
export var me1 = "announcement-module--me-1--F5OZC";
export var me2 = "announcement-module--me-2--+zH3M";
export var me3 = "announcement-module--me-3--GkkqL";
export var me4 = "announcement-module--me-4--l46Td";
export var me5 = "announcement-module--me-5--1XQB3";
export var meAuto = "announcement-module--me-auto--0EzCa";
export var meLg0 = "announcement-module--me-lg-0--v1w3o";
export var meLg1 = "announcement-module--me-lg-1--5rbN9";
export var meLg2 = "announcement-module--me-lg-2--RPMdB";
export var meLg3 = "announcement-module--me-lg-3--1px3U";
export var meLg4 = "announcement-module--me-lg-4--cYP4W";
export var meLg5 = "announcement-module--me-lg-5--cJIv4";
export var meLgAuto = "announcement-module--me-lg-auto--oGJBr";
export var meMd0 = "announcement-module--me-md-0--2JPct";
export var meMd1 = "announcement-module--me-md-1--FIlB4";
export var meMd2 = "announcement-module--me-md-2---wWti";
export var meMd3 = "announcement-module--me-md-3--8YEXr";
export var meMd4 = "announcement-module--me-md-4--xqN+q";
export var meMd5 = "announcement-module--me-md-5--dw7v6";
export var meMdAuto = "announcement-module--me-md-auto--w08y2";
export var meSm0 = "announcement-module--me-sm-0--8Zw1s";
export var meSm1 = "announcement-module--me-sm-1--e1LP0";
export var meSm2 = "announcement-module--me-sm-2--LzgVL";
export var meSm3 = "announcement-module--me-sm-3--QksFq";
export var meSm4 = "announcement-module--me-sm-4--yHMs6";
export var meSm5 = "announcement-module--me-sm-5--YSt18";
export var meSmAuto = "announcement-module--me-sm-auto--t9nKM";
export var meXl0 = "announcement-module--me-xl-0--gdD8B";
export var meXl1 = "announcement-module--me-xl-1--6PBB3";
export var meXl2 = "announcement-module--me-xl-2--7cgo5";
export var meXl3 = "announcement-module--me-xl-3--kJsGu";
export var meXl4 = "announcement-module--me-xl-4--EZ1PC";
export var meXl5 = "announcement-module--me-xl-5--kao4F";
export var meXlAuto = "announcement-module--me-xl-auto--Etc9-";
export var meXxl0 = "announcement-module--me-xxl-0--c6jkY";
export var meXxl1 = "announcement-module--me-xxl-1--50WOk";
export var meXxl2 = "announcement-module--me-xxl-2--o26fA";
export var meXxl3 = "announcement-module--me-xxl-3--XjFSc";
export var meXxl4 = "announcement-module--me-xxl-4--rZ5Pb";
export var meXxl5 = "announcement-module--me-xxl-5--mIIlD";
export var meXxlAuto = "announcement-module--me-xxl-auto--Aj4bn";
export var mh100 = "announcement-module--mh-100--6rsND";
export var minVh100 = "announcement-module--min-vh-100--EQXJR";
export var minVw100 = "announcement-module--min-vw-100--soyYC";
export var modal = "announcement-module--modal--TsQoZ";
export var modalBackdrop = "announcement-module--modal-backdrop--eeR2T";
export var modalBody = "announcement-module--modal-body--iR0Mh";
export var modalContent = "announcement-module--modal-content--sSL37";
export var modalDialog = "announcement-module--modal-dialog--nvbL7";
export var modalDialogCentered = "announcement-module--modal-dialog-centered--EAXWZ";
export var modalDialogScrollable = "announcement-module--modal-dialog-scrollable--eFIy1";
export var modalFooter = "announcement-module--modal-footer--OrYyQ";
export var modalFullscreen = "announcement-module--modal-fullscreen--pBIDM";
export var modalFullscreenLgDown = "announcement-module--modal-fullscreen-lg-down--XDCz1";
export var modalFullscreenMdDown = "announcement-module--modal-fullscreen-md-down--8f+fi";
export var modalFullscreenSmDown = "announcement-module--modal-fullscreen-sm-down--TCV2L";
export var modalFullscreenXlDown = "announcement-module--modal-fullscreen-xl-down--H+M-S";
export var modalFullscreenXxlDown = "announcement-module--modal-fullscreen-xxl-down--dxJXG";
export var modalHeader = "announcement-module--modal-header--k0x5k";
export var modalLg = "announcement-module--modal-lg--qVPWd";
export var modalSm = "announcement-module--modal-sm--8n13G";
export var modalStatic = "announcement-module--modal-static--SpC3e";
export var modalTitle = "announcement-module--modal-title--ionbL";
export var modalXl = "announcement-module--modal-xl--xgEMg";
export var ms0 = "announcement-module--ms-0--JpOMQ";
export var ms1 = "announcement-module--ms-1--6pWJ9";
export var ms2 = "announcement-module--ms-2--mN0hE";
export var ms3 = "announcement-module--ms-3--bnfKi";
export var ms4 = "announcement-module--ms-4--yioCw";
export var ms5 = "announcement-module--ms-5--WJryn";
export var msAuto = "announcement-module--ms-auto--SHjkC";
export var msLg0 = "announcement-module--ms-lg-0--Ew77T";
export var msLg1 = "announcement-module--ms-lg-1--DtErw";
export var msLg2 = "announcement-module--ms-lg-2--98J2R";
export var msLg3 = "announcement-module--ms-lg-3--JDwor";
export var msLg4 = "announcement-module--ms-lg-4--MmhUc";
export var msLg5 = "announcement-module--ms-lg-5--eCka+";
export var msLgAuto = "announcement-module--ms-lg-auto--wfMfq";
export var msMd0 = "announcement-module--ms-md-0--00oss";
export var msMd1 = "announcement-module--ms-md-1--CYUG4";
export var msMd2 = "announcement-module--ms-md-2--XxkA9";
export var msMd3 = "announcement-module--ms-md-3--Ys0PK";
export var msMd4 = "announcement-module--ms-md-4--VX8DG";
export var msMd5 = "announcement-module--ms-md-5--f0Ela";
export var msMdAuto = "announcement-module--ms-md-auto--9ordf";
export var msSm0 = "announcement-module--ms-sm-0--pcc56";
export var msSm1 = "announcement-module--ms-sm-1--rr4sy";
export var msSm2 = "announcement-module--ms-sm-2--QOSld";
export var msSm3 = "announcement-module--ms-sm-3--U3wEw";
export var msSm4 = "announcement-module--ms-sm-4--GYuPH";
export var msSm5 = "announcement-module--ms-sm-5--1Yysz";
export var msSmAuto = "announcement-module--ms-sm-auto--AZVOT";
export var msXl0 = "announcement-module--ms-xl-0--6irhh";
export var msXl1 = "announcement-module--ms-xl-1--0zw00";
export var msXl2 = "announcement-module--ms-xl-2--OzZwW";
export var msXl3 = "announcement-module--ms-xl-3--6r9fT";
export var msXl4 = "announcement-module--ms-xl-4--tA7Mp";
export var msXl5 = "announcement-module--ms-xl-5--nqAVk";
export var msXlAuto = "announcement-module--ms-xl-auto--hKEqN";
export var msXxl0 = "announcement-module--ms-xxl-0--CENJw";
export var msXxl1 = "announcement-module--ms-xxl-1--Rb8Js";
export var msXxl2 = "announcement-module--ms-xxl-2--V6ctP";
export var msXxl3 = "announcement-module--ms-xxl-3--iPnfk";
export var msXxl4 = "announcement-module--ms-xxl-4--emJg9";
export var msXxl5 = "announcement-module--ms-xxl-5--brXae";
export var msXxlAuto = "announcement-module--ms-xxl-auto--QkNV4";
export var mt0 = "announcement-module--mt-0--7utB0";
export var mt1 = "announcement-module--mt-1--fxyTV";
export var mt2 = "announcement-module--mt-2--tD24a";
export var mt3 = "announcement-module--mt-3--Z2l9C";
export var mt4 = "announcement-module--mt-4--boV-l";
export var mt5 = "announcement-module--mt-5--yWnIz";
export var mtAuto = "announcement-module--mt-auto--deHWG";
export var mtLg0 = "announcement-module--mt-lg-0--cWcF4";
export var mtLg1 = "announcement-module--mt-lg-1--1mCzJ";
export var mtLg2 = "announcement-module--mt-lg-2--acZqR";
export var mtLg3 = "announcement-module--mt-lg-3--xXVYI";
export var mtLg4 = "announcement-module--mt-lg-4--HGmOM";
export var mtLg5 = "announcement-module--mt-lg-5--+pNp0";
export var mtLgAuto = "announcement-module--mt-lg-auto--vuyU7";
export var mtMd0 = "announcement-module--mt-md-0--RSY5Y";
export var mtMd1 = "announcement-module--mt-md-1--fK-OD";
export var mtMd2 = "announcement-module--mt-md-2--UnmXn";
export var mtMd3 = "announcement-module--mt-md-3--RC7Bx";
export var mtMd4 = "announcement-module--mt-md-4--karZm";
export var mtMd5 = "announcement-module--mt-md-5--VWpbo";
export var mtMdAuto = "announcement-module--mt-md-auto--QJvMP";
export var mtSm0 = "announcement-module--mt-sm-0--2P15h";
export var mtSm1 = "announcement-module--mt-sm-1--s7GLo";
export var mtSm2 = "announcement-module--mt-sm-2--NkMKf";
export var mtSm3 = "announcement-module--mt-sm-3--2Lao8";
export var mtSm4 = "announcement-module--mt-sm-4--N29jy";
export var mtSm5 = "announcement-module--mt-sm-5--oOkJy";
export var mtSmAuto = "announcement-module--mt-sm-auto--Zdhy4";
export var mtXl0 = "announcement-module--mt-xl-0--I+DUy";
export var mtXl1 = "announcement-module--mt-xl-1--MCvmv";
export var mtXl2 = "announcement-module--mt-xl-2--VSnua";
export var mtXl3 = "announcement-module--mt-xl-3--XMu-8";
export var mtXl4 = "announcement-module--mt-xl-4--er312";
export var mtXl5 = "announcement-module--mt-xl-5--Ji7Df";
export var mtXlAuto = "announcement-module--mt-xl-auto--NbWGe";
export var mtXxl0 = "announcement-module--mt-xxl-0--6QxEI";
export var mtXxl1 = "announcement-module--mt-xxl-1--qX3m4";
export var mtXxl2 = "announcement-module--mt-xxl-2--4m2qw";
export var mtXxl3 = "announcement-module--mt-xxl-3--mQK6V";
export var mtXxl4 = "announcement-module--mt-xxl-4--PX9II";
export var mtXxl5 = "announcement-module--mt-xxl-5--E7So5";
export var mtXxlAuto = "announcement-module--mt-xxl-auto--jA7fv";
export var mw100 = "announcement-module--mw-100--xpyqK";
export var mx0 = "announcement-module--mx-0--Aiw-s";
export var mx1 = "announcement-module--mx-1--g9UMu";
export var mx2 = "announcement-module--mx-2--wj4JT";
export var mx3 = "announcement-module--mx-3--Nhx3F";
export var mx4 = "announcement-module--mx-4--rJUQ-";
export var mx5 = "announcement-module--mx-5--qhYax";
export var mxAuto = "announcement-module--mx-auto--yF4LV";
export var mxLg0 = "announcement-module--mx-lg-0--6Deeq";
export var mxLg1 = "announcement-module--mx-lg-1---UP0P";
export var mxLg2 = "announcement-module--mx-lg-2--JVbPK";
export var mxLg3 = "announcement-module--mx-lg-3--8acH4";
export var mxLg4 = "announcement-module--mx-lg-4--G-pDf";
export var mxLg5 = "announcement-module--mx-lg-5--mj07n";
export var mxLgAuto = "announcement-module--mx-lg-auto--nKT9s";
export var mxMd0 = "announcement-module--mx-md-0--0G0Ly";
export var mxMd1 = "announcement-module--mx-md-1--EBxLz";
export var mxMd2 = "announcement-module--mx-md-2--vfxFW";
export var mxMd3 = "announcement-module--mx-md-3--Opj3K";
export var mxMd4 = "announcement-module--mx-md-4--GbKNU";
export var mxMd5 = "announcement-module--mx-md-5--1N-kI";
export var mxMdAuto = "announcement-module--mx-md-auto--xDqSW";
export var mxSm0 = "announcement-module--mx-sm-0--q+Td4";
export var mxSm1 = "announcement-module--mx-sm-1--8ZVg3";
export var mxSm2 = "announcement-module--mx-sm-2--Bc3zJ";
export var mxSm3 = "announcement-module--mx-sm-3--wi58V";
export var mxSm4 = "announcement-module--mx-sm-4--wfjHx";
export var mxSm5 = "announcement-module--mx-sm-5--Irzou";
export var mxSmAuto = "announcement-module--mx-sm-auto--SxGZD";
export var mxXl0 = "announcement-module--mx-xl-0--vg5lz";
export var mxXl1 = "announcement-module--mx-xl-1--LXADY";
export var mxXl2 = "announcement-module--mx-xl-2--prJ9B";
export var mxXl3 = "announcement-module--mx-xl-3--qiRdt";
export var mxXl4 = "announcement-module--mx-xl-4---5Q4f";
export var mxXl5 = "announcement-module--mx-xl-5--18ABu";
export var mxXlAuto = "announcement-module--mx-xl-auto--X9Rvv";
export var mxXxl0 = "announcement-module--mx-xxl-0--xQY60";
export var mxXxl1 = "announcement-module--mx-xxl-1--5ljeK";
export var mxXxl2 = "announcement-module--mx-xxl-2--8xJeS";
export var mxXxl3 = "announcement-module--mx-xxl-3--eLF8k";
export var mxXxl4 = "announcement-module--mx-xxl-4--7eHdk";
export var mxXxl5 = "announcement-module--mx-xxl-5--tAk91";
export var mxXxlAuto = "announcement-module--mx-xxl-auto--UN7I8";
export var my0 = "announcement-module--my-0--FWff0";
export var my1 = "announcement-module--my-1---3Fwf";
export var my2 = "announcement-module--my-2--qhcrY";
export var my3 = "announcement-module--my-3--zXNL-";
export var my4 = "announcement-module--my-4--QHbZk";
export var my5 = "announcement-module--my-5--DrkoN";
export var myAuto = "announcement-module--my-auto--vxOkl";
export var myLg0 = "announcement-module--my-lg-0--PgYIb";
export var myLg1 = "announcement-module--my-lg-1--0xeGy";
export var myLg2 = "announcement-module--my-lg-2--GPhRi";
export var myLg3 = "announcement-module--my-lg-3--Bd-Dl";
export var myLg4 = "announcement-module--my-lg-4--w5uAE";
export var myLg5 = "announcement-module--my-lg-5--wimwk";
export var myLgAuto = "announcement-module--my-lg-auto--qWCq2";
export var myMd0 = "announcement-module--my-md-0--c1Js-";
export var myMd1 = "announcement-module--my-md-1--t1Tew";
export var myMd2 = "announcement-module--my-md-2--Y8216";
export var myMd3 = "announcement-module--my-md-3--LyNOe";
export var myMd4 = "announcement-module--my-md-4--oNFbZ";
export var myMd5 = "announcement-module--my-md-5--v9jTZ";
export var myMdAuto = "announcement-module--my-md-auto--YXM3v";
export var mySm0 = "announcement-module--my-sm-0--6H34V";
export var mySm1 = "announcement-module--my-sm-1--fpqVR";
export var mySm2 = "announcement-module--my-sm-2--IxVh5";
export var mySm3 = "announcement-module--my-sm-3--lw7s5";
export var mySm4 = "announcement-module--my-sm-4--JEpdn";
export var mySm5 = "announcement-module--my-sm-5--wvKCD";
export var mySmAuto = "announcement-module--my-sm-auto--W7XLo";
export var myXl0 = "announcement-module--my-xl-0--2M3UK";
export var myXl1 = "announcement-module--my-xl-1--2bnkL";
export var myXl2 = "announcement-module--my-xl-2--oCi2c";
export var myXl3 = "announcement-module--my-xl-3--LmuJH";
export var myXl4 = "announcement-module--my-xl-4--Xi1T2";
export var myXl5 = "announcement-module--my-xl-5--IG3tm";
export var myXlAuto = "announcement-module--my-xl-auto--3EuPf";
export var myXxl0 = "announcement-module--my-xxl-0--xGTsg";
export var myXxl1 = "announcement-module--my-xxl-1--JLKHq";
export var myXxl2 = "announcement-module--my-xxl-2--3GSzC";
export var myXxl3 = "announcement-module--my-xxl-3--PndoL";
export var myXxl4 = "announcement-module--my-xxl-4--oxNlG";
export var myXxl5 = "announcement-module--my-xxl-5--XZDvl";
export var myXxlAuto = "announcement-module--my-xxl-auto--U3B6l";
export var nav = "announcement-module--nav--HKFT9";
export var navFill = "announcement-module--nav-fill--Y9k2x";
export var navItem = "announcement-module--nav-item--RaaAb";
export var navJustified = "announcement-module--nav-justified--zgxX9";
export var navLink = "announcement-module--nav-link--VDVMb";
export var navPills = "announcement-module--nav-pills--kRWRX";
export var navTabs = "announcement-module--nav-tabs--FDTab";
export var navbar = "announcement-module--navbar--mOi22";
export var navbarBrand = "announcement-module--navbar-brand--GTO2g";
export var navbarCollapse = "announcement-module--navbar-collapse--vzx-z";
export var navbarDark = "announcement-module--navbar-dark--MQ70I";
export var navbarExpand = "announcement-module--navbar-expand--RP0R2";
export var navbarExpandLg = "announcement-module--navbar-expand-lg--S5T68";
export var navbarExpandMd = "announcement-module--navbar-expand-md--618-I";
export var navbarExpandSm = "announcement-module--navbar-expand-sm--hgx9N";
export var navbarExpandXl = "announcement-module--navbar-expand-xl--al1SZ";
export var navbarExpandXxl = "announcement-module--navbar-expand-xxl--NVqUM";
export var navbarLight = "announcement-module--navbar-light--FJ8C6";
export var navbarNav = "announcement-module--navbar-nav--AZNsd";
export var navbarNavScroll = "announcement-module--navbar-nav-scroll--7baKO";
export var navbarText = "announcement-module--navbar-text--O2Q-S";
export var navbarToggler = "announcement-module--navbar-toggler--WzmQe";
export var navbarTogglerIcon = "announcement-module--navbar-toggler-icon--LORop";
export var offcanvas = "announcement-module--offcanvas--icKfJ";
export var offcanvasBackdrop = "announcement-module--offcanvas-backdrop--ImFTd";
export var offcanvasBody = "announcement-module--offcanvas-body--BZtpb";
export var offcanvasBottom = "announcement-module--offcanvas-bottom--moNAz";
export var offcanvasEnd = "announcement-module--offcanvas-end--upz7D";
export var offcanvasHeader = "announcement-module--offcanvas-header--eXMgj";
export var offcanvasStart = "announcement-module--offcanvas-start--F+9UC";
export var offcanvasTitle = "announcement-module--offcanvas-title--VfecZ";
export var offcanvasTop = "announcement-module--offcanvas-top--0JvM7";
export var offset1 = "announcement-module--offset-1--5q6XP";
export var offset10 = "announcement-module--offset-10--JhzUJ";
export var offset11 = "announcement-module--offset-11--EnK0C";
export var offset2 = "announcement-module--offset-2--A4UZ9";
export var offset3 = "announcement-module--offset-3--mpRV+";
export var offset4 = "announcement-module--offset-4--DR7C7";
export var offset5 = "announcement-module--offset-5--aK8qZ";
export var offset6 = "announcement-module--offset-6--pl9U-";
export var offset7 = "announcement-module--offset-7--7EPla";
export var offset8 = "announcement-module--offset-8--RMiuA";
export var offset9 = "announcement-module--offset-9--GwLGK";
export var offsetLg0 = "announcement-module--offset-lg-0--ymmMq";
export var offsetLg1 = "announcement-module--offset-lg-1--42RFG";
export var offsetLg10 = "announcement-module--offset-lg-10--cIKVR";
export var offsetLg11 = "announcement-module--offset-lg-11--q2p2r";
export var offsetLg2 = "announcement-module--offset-lg-2--VvGi2";
export var offsetLg3 = "announcement-module--offset-lg-3--AeJEr";
export var offsetLg4 = "announcement-module--offset-lg-4--2fah5";
export var offsetLg5 = "announcement-module--offset-lg-5--HiqzK";
export var offsetLg6 = "announcement-module--offset-lg-6--uCBRM";
export var offsetLg7 = "announcement-module--offset-lg-7--3ugQt";
export var offsetLg8 = "announcement-module--offset-lg-8--wBDze";
export var offsetLg9 = "announcement-module--offset-lg-9--JfRjz";
export var offsetMd0 = "announcement-module--offset-md-0--CvwCl";
export var offsetMd1 = "announcement-module--offset-md-1--0jywF";
export var offsetMd10 = "announcement-module--offset-md-10--SZswJ";
export var offsetMd11 = "announcement-module--offset-md-11--sM6OZ";
export var offsetMd2 = "announcement-module--offset-md-2--FpN2N";
export var offsetMd3 = "announcement-module--offset-md-3--c-D7h";
export var offsetMd4 = "announcement-module--offset-md-4--i1Pmt";
export var offsetMd5 = "announcement-module--offset-md-5--2CDAQ";
export var offsetMd6 = "announcement-module--offset-md-6--y9QzH";
export var offsetMd7 = "announcement-module--offset-md-7--QSDks";
export var offsetMd8 = "announcement-module--offset-md-8--p21oN";
export var offsetMd9 = "announcement-module--offset-md-9--DYYDr";
export var offsetSm0 = "announcement-module--offset-sm-0--p23Qg";
export var offsetSm1 = "announcement-module--offset-sm-1--3rVQf";
export var offsetSm10 = "announcement-module--offset-sm-10--bcMMW";
export var offsetSm11 = "announcement-module--offset-sm-11--vmqjW";
export var offsetSm2 = "announcement-module--offset-sm-2--cbIhb";
export var offsetSm3 = "announcement-module--offset-sm-3--YeS9u";
export var offsetSm4 = "announcement-module--offset-sm-4--j4gzv";
export var offsetSm5 = "announcement-module--offset-sm-5--eUNv0";
export var offsetSm6 = "announcement-module--offset-sm-6--x97jK";
export var offsetSm7 = "announcement-module--offset-sm-7--JkRPe";
export var offsetSm8 = "announcement-module--offset-sm-8--3r3al";
export var offsetSm9 = "announcement-module--offset-sm-9--E8Y6W";
export var offsetXl0 = "announcement-module--offset-xl-0--OnZt0";
export var offsetXl1 = "announcement-module--offset-xl-1--Kweue";
export var offsetXl10 = "announcement-module--offset-xl-10--SfSkb";
export var offsetXl11 = "announcement-module--offset-xl-11--CPtyu";
export var offsetXl2 = "announcement-module--offset-xl-2--aicBz";
export var offsetXl3 = "announcement-module--offset-xl-3--7QTzJ";
export var offsetXl4 = "announcement-module--offset-xl-4--FNuZ4";
export var offsetXl5 = "announcement-module--offset-xl-5--voz72";
export var offsetXl6 = "announcement-module--offset-xl-6--1q4h0";
export var offsetXl7 = "announcement-module--offset-xl-7--tHErW";
export var offsetXl8 = "announcement-module--offset-xl-8--F8qrV";
export var offsetXl9 = "announcement-module--offset-xl-9--MXM6T";
export var offsetXxl0 = "announcement-module--offset-xxl-0--TN0gR";
export var offsetXxl1 = "announcement-module--offset-xxl-1--4b0UF";
export var offsetXxl10 = "announcement-module--offset-xxl-10--g+O1Z";
export var offsetXxl11 = "announcement-module--offset-xxl-11--K9fbK";
export var offsetXxl2 = "announcement-module--offset-xxl-2--rOKDs";
export var offsetXxl3 = "announcement-module--offset-xxl-3--NB-2C";
export var offsetXxl4 = "announcement-module--offset-xxl-4--kRsPL";
export var offsetXxl5 = "announcement-module--offset-xxl-5--0Zs0B";
export var offsetXxl6 = "announcement-module--offset-xxl-6--FY5qC";
export var offsetXxl7 = "announcement-module--offset-xxl-7--Ari0f";
export var offsetXxl8 = "announcement-module--offset-xxl-8--0R3BT";
export var offsetXxl9 = "announcement-module--offset-xxl-9--lJrPr";
export var opacity0 = "announcement-module--opacity-0--Jb7es";
export var opacity100 = "announcement-module--opacity-100--i1Kb6";
export var opacity25 = "announcement-module--opacity-25--A9IHs";
export var opacity50 = "announcement-module--opacity-50---O4o-";
export var opacity75 = "announcement-module--opacity-75--IJVBv";
export var order0 = "announcement-module--order-0--OoUWj";
export var order1 = "announcement-module--order-1--nd73S";
export var order2 = "announcement-module--order-2--rl3Dj";
export var order3 = "announcement-module--order-3--aFl-L";
export var order4 = "announcement-module--order-4--tmEC8";
export var order5 = "announcement-module--order-5--Ka1cm";
export var orderFirst = "announcement-module--order-first--O2GWo";
export var orderLast = "announcement-module--order-last--1lK9O";
export var orderLg0 = "announcement-module--order-lg-0--2E-yu";
export var orderLg1 = "announcement-module--order-lg-1--DKPe3";
export var orderLg2 = "announcement-module--order-lg-2--yIZsn";
export var orderLg3 = "announcement-module--order-lg-3--K4yoV";
export var orderLg4 = "announcement-module--order-lg-4--GxTPb";
export var orderLg5 = "announcement-module--order-lg-5--ZTNUt";
export var orderLgFirst = "announcement-module--order-lg-first--BtN-V";
export var orderLgLast = "announcement-module--order-lg-last--GXZFi";
export var orderMd0 = "announcement-module--order-md-0--ffHor";
export var orderMd1 = "announcement-module--order-md-1--+neDM";
export var orderMd2 = "announcement-module--order-md-2--tsyoa";
export var orderMd3 = "announcement-module--order-md-3--Sy1Vr";
export var orderMd4 = "announcement-module--order-md-4--O-M1B";
export var orderMd5 = "announcement-module--order-md-5--0KQyA";
export var orderMdFirst = "announcement-module--order-md-first--URFeQ";
export var orderMdLast = "announcement-module--order-md-last--sYgFg";
export var orderSm0 = "announcement-module--order-sm-0--OtGv6";
export var orderSm1 = "announcement-module--order-sm-1--LpIkL";
export var orderSm2 = "announcement-module--order-sm-2--y+xoX";
export var orderSm3 = "announcement-module--order-sm-3--sm+cS";
export var orderSm4 = "announcement-module--order-sm-4--ClsCL";
export var orderSm5 = "announcement-module--order-sm-5--4u6DT";
export var orderSmFirst = "announcement-module--order-sm-first--ybCSc";
export var orderSmLast = "announcement-module--order-sm-last--86-IP";
export var orderXl0 = "announcement-module--order-xl-0--yrmlW";
export var orderXl1 = "announcement-module--order-xl-1--u+JrD";
export var orderXl2 = "announcement-module--order-xl-2--LdOxK";
export var orderXl3 = "announcement-module--order-xl-3--71UY1";
export var orderXl4 = "announcement-module--order-xl-4--lvY32";
export var orderXl5 = "announcement-module--order-xl-5--9WfJh";
export var orderXlFirst = "announcement-module--order-xl-first--3HmJV";
export var orderXlLast = "announcement-module--order-xl-last--1Sgwf";
export var orderXxl0 = "announcement-module--order-xxl-0--5xA3h";
export var orderXxl1 = "announcement-module--order-xxl-1--t0aPY";
export var orderXxl2 = "announcement-module--order-xxl-2--hlNE+";
export var orderXxl3 = "announcement-module--order-xxl-3---3Z1H";
export var orderXxl4 = "announcement-module--order-xxl-4--fEUSl";
export var orderXxl5 = "announcement-module--order-xxl-5--7Mr3j";
export var orderXxlFirst = "announcement-module--order-xxl-first--DMz+J";
export var orderXxlLast = "announcement-module--order-xxl-last--m4DWZ";
export var overflowAuto = "announcement-module--overflow-auto--6us7n";
export var overflowHidden = "announcement-module--overflow-hidden--7okmL";
export var overflowScroll = "announcement-module--overflow-scroll--HuZeI";
export var overflowVisible = "announcement-module--overflow-visible--oOibw";
export var p0 = "announcement-module--p-0---OY3C";
export var p1 = "announcement-module--p-1--a7TII";
export var p2 = "announcement-module--p-2--wfCDZ";
export var p3 = "announcement-module--p-3--7RfIa";
export var p4 = "announcement-module--p-4--YRo2f";
export var p5 = "announcement-module--p-5---yc33";
export var pLg0 = "announcement-module--p-lg-0--zo0yf";
export var pLg1 = "announcement-module--p-lg-1--FzJSf";
export var pLg2 = "announcement-module--p-lg-2--oLTdR";
export var pLg3 = "announcement-module--p-lg-3--PS+ly";
export var pLg4 = "announcement-module--p-lg-4--Az2NL";
export var pLg5 = "announcement-module--p-lg-5--LRYn-";
export var pMd0 = "announcement-module--p-md-0--weGrk";
export var pMd1 = "announcement-module--p-md-1--N04x5";
export var pMd2 = "announcement-module--p-md-2--U6zGx";
export var pMd3 = "announcement-module--p-md-3--0vkLD";
export var pMd4 = "announcement-module--p-md-4--k-UpI";
export var pMd5 = "announcement-module--p-md-5--LS9f5";
export var pSm0 = "announcement-module--p-sm-0--8ruu4";
export var pSm1 = "announcement-module--p-sm-1--wueYG";
export var pSm2 = "announcement-module--p-sm-2--h89Rw";
export var pSm3 = "announcement-module--p-sm-3--OYgId";
export var pSm4 = "announcement-module--p-sm-4--+YHqP";
export var pSm5 = "announcement-module--p-sm-5--fDgKU";
export var pXl0 = "announcement-module--p-xl-0--rYyO-";
export var pXl1 = "announcement-module--p-xl-1--Yw-Uk";
export var pXl2 = "announcement-module--p-xl-2--oyvoy";
export var pXl3 = "announcement-module--p-xl-3--tcgDt";
export var pXl4 = "announcement-module--p-xl-4--fpSQn";
export var pXl5 = "announcement-module--p-xl-5--tcJrP";
export var pXxl0 = "announcement-module--p-xxl-0--vDh7e";
export var pXxl1 = "announcement-module--p-xxl-1--5pKzh";
export var pXxl2 = "announcement-module--p-xxl-2--sT+n-";
export var pXxl3 = "announcement-module--p-xxl-3--U5YXF";
export var pXxl4 = "announcement-module--p-xxl-4--OtwBf";
export var pXxl5 = "announcement-module--p-xxl-5--j844E";
export var pageItem = "announcement-module--page-item--2dkM1";
export var pageLink = "announcement-module--page-link--RtS4i";
export var pagination = "announcement-module--pagination--54ir1";
export var paginationLg = "announcement-module--pagination-lg--BIjoj";
export var paginationSm = "announcement-module--pagination-sm--io+8W";
export var pb0 = "announcement-module--pb-0--AU8QG";
export var pb1 = "announcement-module--pb-1--FP3W2";
export var pb2 = "announcement-module--pb-2--YPq6W";
export var pb3 = "announcement-module--pb-3--DDbOk";
export var pb4 = "announcement-module--pb-4--BR3XA";
export var pb5 = "announcement-module--pb-5--fhtmC";
export var pbLg0 = "announcement-module--pb-lg-0--2+97X";
export var pbLg1 = "announcement-module--pb-lg-1--2gIdr";
export var pbLg2 = "announcement-module--pb-lg-2--Ktciw";
export var pbLg3 = "announcement-module--pb-lg-3--KYpOH";
export var pbLg4 = "announcement-module--pb-lg-4--qf-nR";
export var pbLg5 = "announcement-module--pb-lg-5--xddVc";
export var pbMd0 = "announcement-module--pb-md-0--Dqjpe";
export var pbMd1 = "announcement-module--pb-md-1--ra+eI";
export var pbMd2 = "announcement-module--pb-md-2--GAqWJ";
export var pbMd3 = "announcement-module--pb-md-3--Amt5N";
export var pbMd4 = "announcement-module--pb-md-4--TIDkA";
export var pbMd5 = "announcement-module--pb-md-5--Ho0XU";
export var pbSm0 = "announcement-module--pb-sm-0--EMhp1";
export var pbSm1 = "announcement-module--pb-sm-1--Wk-8S";
export var pbSm2 = "announcement-module--pb-sm-2--gU7PR";
export var pbSm3 = "announcement-module--pb-sm-3--Q+g4f";
export var pbSm4 = "announcement-module--pb-sm-4--T2b1V";
export var pbSm5 = "announcement-module--pb-sm-5--IDkSV";
export var pbXl0 = "announcement-module--pb-xl-0--vm8kE";
export var pbXl1 = "announcement-module--pb-xl-1--HPV8V";
export var pbXl2 = "announcement-module--pb-xl-2--ULbCr";
export var pbXl3 = "announcement-module--pb-xl-3--dakVp";
export var pbXl4 = "announcement-module--pb-xl-4--fU+VC";
export var pbXl5 = "announcement-module--pb-xl-5--nTH4t";
export var pbXxl0 = "announcement-module--pb-xxl-0--1-DRO";
export var pbXxl1 = "announcement-module--pb-xxl-1--cYiAa";
export var pbXxl2 = "announcement-module--pb-xxl-2--JSdQV";
export var pbXxl3 = "announcement-module--pb-xxl-3--IP1fN";
export var pbXxl4 = "announcement-module--pb-xxl-4--o1BrH";
export var pbXxl5 = "announcement-module--pb-xxl-5--2fIzD";
export var pe0 = "announcement-module--pe-0--U0kp0";
export var pe1 = "announcement-module--pe-1--WQdpq";
export var pe2 = "announcement-module--pe-2--gVYy5";
export var pe3 = "announcement-module--pe-3--84+6B";
export var pe4 = "announcement-module--pe-4--U1YKK";
export var pe5 = "announcement-module--pe-5--WCZww";
export var peAuto = "announcement-module--pe-auto--fwPL6";
export var peLg0 = "announcement-module--pe-lg-0--g9P-c";
export var peLg1 = "announcement-module--pe-lg-1--rxVsV";
export var peLg2 = "announcement-module--pe-lg-2--C0jC+";
export var peLg3 = "announcement-module--pe-lg-3--4L1yO";
export var peLg4 = "announcement-module--pe-lg-4--2Jq-t";
export var peLg5 = "announcement-module--pe-lg-5--e4+eA";
export var peMd0 = "announcement-module--pe-md-0--N1bOZ";
export var peMd1 = "announcement-module--pe-md-1--lS9NS";
export var peMd2 = "announcement-module--pe-md-2--JI8ZO";
export var peMd3 = "announcement-module--pe-md-3--H6X3r";
export var peMd4 = "announcement-module--pe-md-4--vwyfe";
export var peMd5 = "announcement-module--pe-md-5--vDKXV";
export var peNone = "announcement-module--pe-none--vH8Ix";
export var peSm0 = "announcement-module--pe-sm-0--HL8eX";
export var peSm1 = "announcement-module--pe-sm-1--1EaKa";
export var peSm2 = "announcement-module--pe-sm-2--zqAS9";
export var peSm3 = "announcement-module--pe-sm-3--8FBg0";
export var peSm4 = "announcement-module--pe-sm-4--LXDmk";
export var peSm5 = "announcement-module--pe-sm-5--RxhyS";
export var peXl0 = "announcement-module--pe-xl-0--yh6qC";
export var peXl1 = "announcement-module--pe-xl-1--V+3hn";
export var peXl2 = "announcement-module--pe-xl-2--j2N7P";
export var peXl3 = "announcement-module--pe-xl-3--7msjg";
export var peXl4 = "announcement-module--pe-xl-4--BjS9x";
export var peXl5 = "announcement-module--pe-xl-5--iS1nV";
export var peXxl0 = "announcement-module--pe-xxl-0--OvWQ4";
export var peXxl1 = "announcement-module--pe-xxl-1--Hc0DQ";
export var peXxl2 = "announcement-module--pe-xxl-2--bonxw";
export var peXxl3 = "announcement-module--pe-xxl-3--Tl0Uq";
export var peXxl4 = "announcement-module--pe-xxl-4--NrhQ8";
export var peXxl5 = "announcement-module--pe-xxl-5--ZfBx7";
export var placeholder = "announcement-module--placeholder--E30vu";
export var placeholderGlow = "announcement-module--placeholder-glow--3IlhB";
export var placeholderLg = "announcement-module--placeholder-lg--YBMeu";
export var placeholderSm = "announcement-module--placeholder-sm--8OqBa";
export var placeholderWave = "announcement-module--placeholder-wave--AZo9Q";
export var placeholderXs = "announcement-module--placeholder-xs--m7rGB";
export var pointerEvent = "announcement-module--pointer-event--ouVl1";
export var popover = "announcement-module--popover--JgJlz";
export var popoverArrow = "announcement-module--popover-arrow--I+5mw";
export var popoverBody = "announcement-module--popover-body--h2Fhk";
export var popoverHeader = "announcement-module--popover-header--DX47o";
export var positionAbsolute = "announcement-module--position-absolute--s-Cwp";
export var positionFixed = "announcement-module--position-fixed--Ip4JP";
export var positionRelative = "announcement-module--position-relative---U7k3";
export var positionStatic = "announcement-module--position-static--xue11";
export var positionSticky = "announcement-module--position-sticky--XhQF3";
export var progress = "announcement-module--progress--TUKmH";
export var progressBar = "announcement-module--progress-bar--psPY8";
export var progressBarAnimated = "announcement-module--progress-bar-animated--Lj8C1";
export var progressBarStriped = "announcement-module--progress-bar-striped--UoioT";
export var progressBarStripes = "announcement-module--progress-bar-stripes--IzfDR";
export var ps0 = "announcement-module--ps-0--bPMcS";
export var ps1 = "announcement-module--ps-1--6Dz6B";
export var ps2 = "announcement-module--ps-2--u6wUO";
export var ps3 = "announcement-module--ps-3--hE6IJ";
export var ps4 = "announcement-module--ps-4--OXhs-";
export var ps5 = "announcement-module--ps-5--lpR8u";
export var psLg0 = "announcement-module--ps-lg-0--A2QvM";
export var psLg1 = "announcement-module--ps-lg-1--cfDSX";
export var psLg2 = "announcement-module--ps-lg-2--7qsmq";
export var psLg3 = "announcement-module--ps-lg-3--wmBlz";
export var psLg4 = "announcement-module--ps-lg-4--n3UwS";
export var psLg5 = "announcement-module--ps-lg-5--m2oU1";
export var psMd0 = "announcement-module--ps-md-0--bB5FA";
export var psMd1 = "announcement-module--ps-md-1--3Z2ud";
export var psMd2 = "announcement-module--ps-md-2--h2uf8";
export var psMd3 = "announcement-module--ps-md-3--LL96w";
export var psMd4 = "announcement-module--ps-md-4--HL5DT";
export var psMd5 = "announcement-module--ps-md-5--UCDn4";
export var psSm0 = "announcement-module--ps-sm-0--4sO5G";
export var psSm1 = "announcement-module--ps-sm-1--px4Ux";
export var psSm2 = "announcement-module--ps-sm-2--GbNE3";
export var psSm3 = "announcement-module--ps-sm-3--n5pgd";
export var psSm4 = "announcement-module--ps-sm-4--m8BL8";
export var psSm5 = "announcement-module--ps-sm-5--X3UXz";
export var psXl0 = "announcement-module--ps-xl-0--wCFlX";
export var psXl1 = "announcement-module--ps-xl-1--3KIIx";
export var psXl2 = "announcement-module--ps-xl-2--f4okv";
export var psXl3 = "announcement-module--ps-xl-3--W6blJ";
export var psXl4 = "announcement-module--ps-xl-4--xG5fk";
export var psXl5 = "announcement-module--ps-xl-5--ZOfqO";
export var psXxl0 = "announcement-module--ps-xxl-0--35Dxo";
export var psXxl1 = "announcement-module--ps-xxl-1--JrIuS";
export var psXxl2 = "announcement-module--ps-xxl-2--BOM2L";
export var psXxl3 = "announcement-module--ps-xxl-3--Eb7Vr";
export var psXxl4 = "announcement-module--ps-xxl-4--6YguK";
export var psXxl5 = "announcement-module--ps-xxl-5--XvYxf";
export var pt0 = "announcement-module--pt-0--sbqxu";
export var pt1 = "announcement-module--pt-1--RV9h+";
export var pt2 = "announcement-module--pt-2--PidFD";
export var pt3 = "announcement-module--pt-3--JKW7x";
export var pt4 = "announcement-module--pt-4--MSOrc";
export var pt5 = "announcement-module--pt-5--CB0Vp";
export var ptLg0 = "announcement-module--pt-lg-0--nXZPV";
export var ptLg1 = "announcement-module--pt-lg-1--KT3OQ";
export var ptLg2 = "announcement-module--pt-lg-2--Y8FOZ";
export var ptLg3 = "announcement-module--pt-lg-3--h2Aae";
export var ptLg4 = "announcement-module--pt-lg-4--47Ncu";
export var ptLg5 = "announcement-module--pt-lg-5--GFXyd";
export var ptMd0 = "announcement-module--pt-md-0--LWRP5";
export var ptMd1 = "announcement-module--pt-md-1--Nn31T";
export var ptMd2 = "announcement-module--pt-md-2--tcJvn";
export var ptMd3 = "announcement-module--pt-md-3--iM+wg";
export var ptMd4 = "announcement-module--pt-md-4--MXYoD";
export var ptMd5 = "announcement-module--pt-md-5--0Jpa2";
export var ptSm0 = "announcement-module--pt-sm-0--4W5ay";
export var ptSm1 = "announcement-module--pt-sm-1--iznm7";
export var ptSm2 = "announcement-module--pt-sm-2--68Inp";
export var ptSm3 = "announcement-module--pt-sm-3--EBnMk";
export var ptSm4 = "announcement-module--pt-sm-4--UNfjz";
export var ptSm5 = "announcement-module--pt-sm-5--9o3g8";
export var ptXl0 = "announcement-module--pt-xl-0--eEAk6";
export var ptXl1 = "announcement-module--pt-xl-1--7GJXt";
export var ptXl2 = "announcement-module--pt-xl-2--lkBYv";
export var ptXl3 = "announcement-module--pt-xl-3--P+3XQ";
export var ptXl4 = "announcement-module--pt-xl-4--9qpWM";
export var ptXl5 = "announcement-module--pt-xl-5--qQFkQ";
export var ptXxl0 = "announcement-module--pt-xxl-0--+Qx28";
export var ptXxl1 = "announcement-module--pt-xxl-1--cbOhJ";
export var ptXxl2 = "announcement-module--pt-xxl-2--f8DXm";
export var ptXxl3 = "announcement-module--pt-xxl-3--J1lf-";
export var ptXxl4 = "announcement-module--pt-xxl-4--XSbq4";
export var ptXxl5 = "announcement-module--pt-xxl-5--MdXmt";
export var px0 = "announcement-module--px-0--NurTt";
export var px1 = "announcement-module--px-1--OoHJm";
export var px2 = "announcement-module--px-2--KAgIr";
export var px3 = "announcement-module--px-3--8zshE";
export var px4 = "announcement-module--px-4--V+LE4";
export var px5 = "announcement-module--px-5--Z11Hm";
export var pxLg0 = "announcement-module--px-lg-0--UItm6";
export var pxLg1 = "announcement-module--px-lg-1--lE8JM";
export var pxLg2 = "announcement-module--px-lg-2--Iwc1C";
export var pxLg3 = "announcement-module--px-lg-3--8HaE+";
export var pxLg4 = "announcement-module--px-lg-4--IUXWS";
export var pxLg5 = "announcement-module--px-lg-5---GohU";
export var pxMd0 = "announcement-module--px-md-0--WRXqZ";
export var pxMd1 = "announcement-module--px-md-1--q2i2X";
export var pxMd2 = "announcement-module--px-md-2--DCf-L";
export var pxMd3 = "announcement-module--px-md-3--1E9Nl";
export var pxMd4 = "announcement-module--px-md-4--hVLPh";
export var pxMd5 = "announcement-module--px-md-5--+obw4";
export var pxSm0 = "announcement-module--px-sm-0--MKgZR";
export var pxSm1 = "announcement-module--px-sm-1--5kMUs";
export var pxSm2 = "announcement-module--px-sm-2--wHxI2";
export var pxSm3 = "announcement-module--px-sm-3--WcW0l";
export var pxSm4 = "announcement-module--px-sm-4--EmoGa";
export var pxSm5 = "announcement-module--px-sm-5--9LTa5";
export var pxXl0 = "announcement-module--px-xl-0--gnwXb";
export var pxXl1 = "announcement-module--px-xl-1--0EjTZ";
export var pxXl2 = "announcement-module--px-xl-2--bed1H";
export var pxXl3 = "announcement-module--px-xl-3--F79GH";
export var pxXl4 = "announcement-module--px-xl-4--7nJw9";
export var pxXl5 = "announcement-module--px-xl-5--jXwY4";
export var pxXxl0 = "announcement-module--px-xxl-0--Hmf6J";
export var pxXxl1 = "announcement-module--px-xxl-1--jjG98";
export var pxXxl2 = "announcement-module--px-xxl-2--c8UZ8";
export var pxXxl3 = "announcement-module--px-xxl-3--wNJH0";
export var pxXxl4 = "announcement-module--px-xxl-4--sg-93";
export var pxXxl5 = "announcement-module--px-xxl-5--5j6av";
export var py0 = "announcement-module--py-0--bBQuj";
export var py1 = "announcement-module--py-1--s5wW0";
export var py2 = "announcement-module--py-2--Y7VRQ";
export var py3 = "announcement-module--py-3--om-Rz";
export var py4 = "announcement-module--py-4--a6etd";
export var py5 = "announcement-module--py-5--1BnPQ";
export var pyLg0 = "announcement-module--py-lg-0--rWyty";
export var pyLg1 = "announcement-module--py-lg-1--pjRkK";
export var pyLg2 = "announcement-module--py-lg-2--QThcn";
export var pyLg3 = "announcement-module--py-lg-3--vBF3c";
export var pyLg4 = "announcement-module--py-lg-4--JTkyw";
export var pyLg5 = "announcement-module--py-lg-5--BaSsp";
export var pyMd0 = "announcement-module--py-md-0--AVjyY";
export var pyMd1 = "announcement-module--py-md-1--W4X6J";
export var pyMd2 = "announcement-module--py-md-2--sm5Lg";
export var pyMd3 = "announcement-module--py-md-3--aoUQn";
export var pyMd4 = "announcement-module--py-md-4--uMpkA";
export var pyMd5 = "announcement-module--py-md-5--OqF-W";
export var pySm0 = "announcement-module--py-sm-0--D14MQ";
export var pySm1 = "announcement-module--py-sm-1--rSnXW";
export var pySm2 = "announcement-module--py-sm-2--Qe3k0";
export var pySm3 = "announcement-module--py-sm-3--PQAvg";
export var pySm4 = "announcement-module--py-sm-4--eV0Gk";
export var pySm5 = "announcement-module--py-sm-5--Zne9n";
export var pyXl0 = "announcement-module--py-xl-0--QKqB-";
export var pyXl1 = "announcement-module--py-xl-1--sHrW-";
export var pyXl2 = "announcement-module--py-xl-2--E95CL";
export var pyXl3 = "announcement-module--py-xl-3--IRb4c";
export var pyXl4 = "announcement-module--py-xl-4--Isnze";
export var pyXl5 = "announcement-module--py-xl-5--yY9-h";
export var pyXxl0 = "announcement-module--py-xxl-0--eCyb7";
export var pyXxl1 = "announcement-module--py-xxl-1--TlEtT";
export var pyXxl2 = "announcement-module--py-xxl-2--Xk42N";
export var pyXxl3 = "announcement-module--py-xxl-3--c1Zzd";
export var pyXxl4 = "announcement-module--py-xxl-4--aXIDG";
export var pyXxl5 = "announcement-module--py-xxl-5--HngsE";
export var ratio = "announcement-module--ratio--WpkB1";
export var ratio16x9 = "announcement-module--ratio-16x9--a+yyb";
export var ratio1x1 = "announcement-module--ratio-1x1--QCrK1";
export var ratio21x9 = "announcement-module--ratio-21x9--llq0N";
export var ratio4x3 = "announcement-module--ratio-4x3--zpOB6";
export var rounded = "announcement-module--rounded--q+r18";
export var rounded0 = "announcement-module--rounded-0--ETTdN";
export var rounded1 = "announcement-module--rounded-1--Tllsf";
export var rounded2 = "announcement-module--rounded-2--orM+F";
export var rounded3 = "announcement-module--rounded-3--AkFw9";
export var roundedBottom = "announcement-module--rounded-bottom--ErNu1";
export var roundedCircle = "announcement-module--rounded-circle--E6Gv9";
export var roundedEnd = "announcement-module--rounded-end--ws0+D";
export var roundedPill = "announcement-module--rounded-pill--KNcf7";
export var roundedStart = "announcement-module--rounded-start--IPBgf";
export var roundedTop = "announcement-module--rounded-top--hzVCQ";
export var row = "announcement-module--row--XVdki";
export var rowCols1 = "announcement-module--row-cols-1--aVz1A";
export var rowCols2 = "announcement-module--row-cols-2--GhVEg";
export var rowCols3 = "announcement-module--row-cols-3--hnpWk";
export var rowCols4 = "announcement-module--row-cols-4--Xauxk";
export var rowCols5 = "announcement-module--row-cols-5--BkJPv";
export var rowCols6 = "announcement-module--row-cols-6--MV5sF";
export var rowColsAuto = "announcement-module--row-cols-auto--GBuwq";
export var rowColsLg1 = "announcement-module--row-cols-lg-1--arttb";
export var rowColsLg2 = "announcement-module--row-cols-lg-2--rGBfg";
export var rowColsLg3 = "announcement-module--row-cols-lg-3--EUk-9";
export var rowColsLg4 = "announcement-module--row-cols-lg-4--bTiB3";
export var rowColsLg5 = "announcement-module--row-cols-lg-5--QOqKZ";
export var rowColsLg6 = "announcement-module--row-cols-lg-6--qgsyM";
export var rowColsLgAuto = "announcement-module--row-cols-lg-auto--44KuL";
export var rowColsMd1 = "announcement-module--row-cols-md-1--QdkCy";
export var rowColsMd2 = "announcement-module--row-cols-md-2--+wLVP";
export var rowColsMd3 = "announcement-module--row-cols-md-3--lvkln";
export var rowColsMd4 = "announcement-module--row-cols-md-4--Jw9cS";
export var rowColsMd5 = "announcement-module--row-cols-md-5--+RNrR";
export var rowColsMd6 = "announcement-module--row-cols-md-6--XMsbM";
export var rowColsMdAuto = "announcement-module--row-cols-md-auto--u4gXz";
export var rowColsSm1 = "announcement-module--row-cols-sm-1--ME7dk";
export var rowColsSm2 = "announcement-module--row-cols-sm-2--anoVj";
export var rowColsSm3 = "announcement-module--row-cols-sm-3--95orS";
export var rowColsSm4 = "announcement-module--row-cols-sm-4--fa7hP";
export var rowColsSm5 = "announcement-module--row-cols-sm-5--CyWBG";
export var rowColsSm6 = "announcement-module--row-cols-sm-6--6H+Xq";
export var rowColsSmAuto = "announcement-module--row-cols-sm-auto--cdffl";
export var rowColsXl1 = "announcement-module--row-cols-xl-1--KC9Rg";
export var rowColsXl2 = "announcement-module--row-cols-xl-2--6VLc+";
export var rowColsXl3 = "announcement-module--row-cols-xl-3--gLxjW";
export var rowColsXl4 = "announcement-module--row-cols-xl-4--VGl-u";
export var rowColsXl5 = "announcement-module--row-cols-xl-5--HXDrl";
export var rowColsXl6 = "announcement-module--row-cols-xl-6--zKsGc";
export var rowColsXlAuto = "announcement-module--row-cols-xl-auto--HRKSB";
export var rowColsXxl1 = "announcement-module--row-cols-xxl-1--mvgFV";
export var rowColsXxl2 = "announcement-module--row-cols-xxl-2--uwvME";
export var rowColsXxl3 = "announcement-module--row-cols-xxl-3---SDi5";
export var rowColsXxl4 = "announcement-module--row-cols-xxl-4--Yqgat";
export var rowColsXxl5 = "announcement-module--row-cols-xxl-5--H3fBy";
export var rowColsXxl6 = "announcement-module--row-cols-xxl-6--MFCb6";
export var rowColsXxlAuto = "announcement-module--row-cols-xxl-auto--itEH4";
export var shadow = "announcement-module--shadow--9dRRg";
export var shadowLg = "announcement-module--shadow-lg--9jwp0";
export var shadowNone = "announcement-module--shadow-none--MGg+M";
export var shadowSm = "announcement-module--shadow-sm--yUniV";
export var show = "announcement-module--show--FQ9rC";
export var showing = "announcement-module--showing--NVqeq";
export var small = "announcement-module--small--KeP5m";
export var spinnerBorder = "announcement-module--spinner-border--AlOMC";
export var spinnerBorderSm = "announcement-module--spinner-border-sm--ocyiz";
export var spinnerGrow = "announcement-module--spinner-grow--D9MpN";
export var spinnerGrowSm = "announcement-module--spinner-grow-sm--ymgL7";
export var start0 = "announcement-module--start-0--RAgeU";
export var start100 = "announcement-module--start-100--rl339";
export var start50 = "announcement-module--start-50--hCmER";
export var stickyLgTop = "announcement-module--sticky-lg-top--CBLVr";
export var stickyMdTop = "announcement-module--sticky-md-top--lEYpN";
export var stickySmTop = "announcement-module--sticky-sm-top--7nyMW";
export var stickyTop = "announcement-module--sticky-top--p-kwB";
export var stickyXlTop = "announcement-module--sticky-xl-top--kb+Dw";
export var stickyXxlTop = "announcement-module--sticky-xxl-top--wrYVl";
export var stretchedLink = "announcement-module--stretched-link--i+CDY";
export var tabContent = "announcement-module--tab-content---joP+";
export var tabPane = "announcement-module--tab-pane--966UC";
export var table = "announcement-module--table--5D--t";
export var tableActive = "announcement-module--table-active--RZelw";
export var tableBordered = "announcement-module--table-bordered--L-+Nl";
export var tableBorderless = "announcement-module--table-borderless--NdWrB";
export var tableDanger = "announcement-module--table-danger--lg2OO";
export var tableDark = "announcement-module--table-dark--yT8Qt";
export var tableHover = "announcement-module--table-hover--T7Ulo";
export var tableInfo = "announcement-module--table-info--j4sZp";
export var tableLight = "announcement-module--table-light--rGyPJ";
export var tablePrimary = "announcement-module--table-primary--lWVIx";
export var tableResponsive = "announcement-module--table-responsive--STVvM";
export var tableResponsiveLg = "announcement-module--table-responsive-lg--wIB2b";
export var tableResponsiveMd = "announcement-module--table-responsive-md--W3ghv";
export var tableResponsiveSm = "announcement-module--table-responsive-sm--0wmhm";
export var tableResponsiveXl = "announcement-module--table-responsive-xl--DUO0W";
export var tableResponsiveXxl = "announcement-module--table-responsive-xxl--O0gN7";
export var tableSecondary = "announcement-module--table-secondary--c7PZQ";
export var tableSm = "announcement-module--table-sm--P4C56";
export var tableStriped = "announcement-module--table-striped--jny-f";
export var tableSuccess = "announcement-module--table-success--xq4b2";
export var tableWarning = "announcement-module--table-warning--+MtZj";
export var textBlack = "announcement-module--text-black--64AA5";
export var textBlack50 = "announcement-module--text-black-50--4RV0i";
export var textBody = "announcement-module--text-body---2upJ";
export var textBreak = "announcement-module--text-break--fH2vv";
export var textCapitalize = "announcement-module--text-capitalize--Cv7nn";
export var textCenter = "announcement-module--text-center--Z7E+W";
export var textDanger = "announcement-module--text-danger--yaE6M";
export var textDark = "announcement-module--text-dark--fvWe3";
export var textDecorationLineThrough = "announcement-module--text-decoration-line-through--iZoxl";
export var textDecorationNone = "announcement-module--text-decoration-none--qnDGr";
export var textDecorationUnderline = "announcement-module--text-decoration-underline--6toiO";
export var textEnd = "announcement-module--text-end--bAYRx";
export var textInfo = "announcement-module--text-info--34T+f";
export var textLgCenter = "announcement-module--text-lg-center--fKPja";
export var textLgEnd = "announcement-module--text-lg-end--8LMHG";
export var textLgStart = "announcement-module--text-lg-start--WOdr9";
export var textLight = "announcement-module--text-light--R2jnT";
export var textLowercase = "announcement-module--text-lowercase--lXcPV";
export var textMdCenter = "announcement-module--text-md-center--mYbig";
export var textMdEnd = "announcement-module--text-md-end--45wB3";
export var textMdStart = "announcement-module--text-md-start--wgl3+";
export var textMuted = "announcement-module--text-muted--2jqaN";
export var textNowrap = "announcement-module--text-nowrap--FYx1q";
export var textOpacity100 = "announcement-module--text-opacity-100--vsAMu";
export var textOpacity25 = "announcement-module--text-opacity-25--YnVwN";
export var textOpacity50 = "announcement-module--text-opacity-50--iFiWU";
export var textOpacity75 = "announcement-module--text-opacity-75--1gw-c";
export var textPrimary = "announcement-module--text-primary--wsQQM";
export var textReset = "announcement-module--text-reset--fNoW8";
export var textSecondary = "announcement-module--text-secondary--kQrRv";
export var textSmCenter = "announcement-module--text-sm-center--Th5UT";
export var textSmEnd = "announcement-module--text-sm-end--eFc92";
export var textSmStart = "announcement-module--text-sm-start--rorpY";
export var textStart = "announcement-module--text-start--465P9";
export var textSuccess = "announcement-module--text-success--3bb0C";
export var textTruncate = "announcement-module--text-truncate--r54mu";
export var textUppercase = "announcement-module--text-uppercase--EF+ot";
export var textWarning = "announcement-module--text-warning--3Fiwd";
export var textWhite = "announcement-module--text-white--9R9vG";
export var textWhite50 = "announcement-module--text-white-50--NYI1o";
export var textWrap = "announcement-module--text-wrap--WipFL";
export var textXlCenter = "announcement-module--text-xl-center--6Y14b";
export var textXlEnd = "announcement-module--text-xl-end--piBwx";
export var textXlStart = "announcement-module--text-xl-start--YwLtY";
export var textXxlCenter = "announcement-module--text-xxl-center--U8EED";
export var textXxlEnd = "announcement-module--text-xxl-end--AlYrM";
export var textXxlStart = "announcement-module--text-xxl-start--1+xB6";
export var toast = "announcement-module--toast--hWsGv";
export var toastBody = "announcement-module--toast-body--va3Xy";
export var toastContainer = "announcement-module--toast-container--zoqwk";
export var toastHeader = "announcement-module--toast-header--K9GTT";
export var tooltip = "announcement-module--tooltip--C4GZL";
export var tooltipArrow = "announcement-module--tooltip-arrow--CJckC";
export var tooltipInner = "announcement-module--tooltip-inner--av26Y";
export var top0 = "announcement-module--top-0--YRlUy";
export var top100 = "announcement-module--top-100--FEyVu";
export var top50 = "announcement-module--top-50--bB6cl";
export var translateMiddle = "announcement-module--translate-middle--ILU5K";
export var translateMiddleX = "announcement-module--translate-middle-x--RmpzB";
export var translateMiddleY = "announcement-module--translate-middle-y--yjqH1";
export var userSelectAll = "announcement-module--user-select-all---C0fX";
export var userSelectAuto = "announcement-module--user-select-auto--AoPX7";
export var userSelectNone = "announcement-module--user-select-none--V0+Q5";
export var validFeedback = "announcement-module--valid-feedback--vU3oW";
export var validTooltip = "announcement-module--valid-tooltip--5rn29";
export var vh100 = "announcement-module--vh-100--sr9yz";
export var visible = "announcement-module--visible--OSHdQ";
export var visuallyHidden = "announcement-module--visually-hidden--slKWE";
export var visuallyHiddenFocusable = "announcement-module--visually-hidden-focusable--KYsnt";
export var vr = "announcement-module--vr--+noZ1";
export var vstack = "announcement-module--vstack--zW07R";
export var vw100 = "announcement-module--vw-100--YFHoB";
export var w100 = "announcement-module--w-100--Y+03e";
export var w25 = "announcement-module--w-25--KQqzQ";
export var w50 = "announcement-module--w-50--btxKg";
export var w75 = "announcement-module--w-75--5tS4T";
export var wAuto = "announcement-module--w-auto--G+0TH";
export var wasValidated = "announcement-module--was-validated--+CKDB";