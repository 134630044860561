import { graphql, useStaticQuery } from "gatsby"

export default function useGallaryData() {
  const data = useStaticQuery(graphql`
    query useGallaryData {
      allMarkdownRemark(filter: { fields: { slug: { eq: "gallery" } } }) {
        edges {
          node {
            id
            frontmatter {
              images {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt(pruneLength: 200)
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return data.allMarkdownRemark.edges
}
