import React from "react"
import * as heroStyles from "../styles/components/hero.module.scss"
import Socials from "./Socials"
import SvgLogo from "../../static/heather-logo.inline.svg"
import useSiteMetaData from "../static_queries/useSiteMetadata"
import { Link } from "gatsby"

export default function Hero(props) {
  const { frontPageData } = useSiteMetaData()
  return (
    <div className={heroStyles.hero__container}>
      <div className={heroStyles.hero}>
        <div className={heroStyles.logo}>
          <SvgLogo />
        </div>
        <div className={heroStyles.logoMobile}>
          <SvgLogo />
        </div>
        <div className={heroStyles.bio}>
          <span>
            <div
              dangerouslySetInnerHTML={{
                __html: frontPageData.desktop_logo_line,
              }}
            />
          </span>
        </div>
        <div className={heroStyles.bioMobile}>
          <span>
            <div
              dangerouslySetInnerHTML={{
                __html: frontPageData.mobile_logo_line,
              }}
            />
          </span>
        </div>
        <Socials />
        <div className={heroStyles.heroPressLine}>
          © Heather {(new Date().getFullYear())} • <Link to="press">Electronic Press Kit (EPK)</Link>
        </div>
      </div>
    </div>
  )
}
